import PropTypes from 'prop-types';
import React from 'react';
import { List, Typography, Box } from '@mui/material';
import { hasAnyPermission } from 'utils/permissions';
import { useSelector } from 'react-redux';
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

const NavGroup = ({ item }) => {
  const authorities = useSelector((state) => state.session.user.authorities);

  const authoritiesForAlumni = authorities?.filter((permission) => {
    return permission === 'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ALUMNI_DASHBOARD';
  });

  const roleForAlumni = authorities?.filter((permission) => {
    return permission === 'ROLE_ALUMNI';
  });

  const getMenuPermissionsToRender = (menu) => {
    if (authoritiesForAlumni?.length > 0 && roleForAlumni?.length > 0) {
      return hasAnyPermission(authoritiesForAlumni, menu.permissions);
    }
    if (authoritiesForAlumni?.length > 0 && roleForAlumni?.length === 0) {
      let filteredAuthorities = authorities?.filter((permission) => {
        return permission !== 'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_ALUMNI_DASHBOARD';
      });
      return hasAnyPermission(filteredAuthorities, menu?.permissions);
    }
    return hasAnyPermission(authorities, menu?.permissions);
  };

  const items = item.children?.map((menu) => {
    const menuPermission = getMenuPermissionsToRender(menu);
    switch (menu.type) {
      case 'collapse':
        return menuPermission ? <NavCollapse key={menu.id} menu={menu} level={1} /> : null;
      case 'item':
        return menuPermission ? <NavItem key={menu.id} item={menu} level={1} /> : null;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  return (
    <List
      subheader={
        item.title && (
          <Box sx={{ marginBottom: '2.5rem' }}>
            <Typography variant="subtitle2">{item.title}</Typography>
          </Box>
        )
      }
    >
      {items}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.node),
  }).isRequired,
};

export default NavGroup;
