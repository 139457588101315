import React, { useRef } from 'react';
import BlankTemplate from 'components/Templates/BlankTemplate';
import PropTypes from 'prop-types';
import CompareTDS from 'components/Organisms/MyPay/CompareTDS';
import { useReactToPrint } from 'react-to-print';

const CompareTDSTemplateTemplate = ({
  empName,
  empId,
  currencySymbol,
  categoryColumnsData,
  categoryRowsData,
  amountData,
  hraAmount,
  totalRentPaid,
  taxOnEmployment,
  deductionTableData,
  totalDeductionAmount,
  hraData,
  incomeData,
  employerNames,
  statutoryData,
  tdsWorkingProfile,
  totalExemptionPrice,
  startDate,
  endDate,
  basicPerc,
  standardDeduction,
}) => {
  const breadcrumbData = [
    { id: 1, name: 'Declaration Details for TDS', path: '', isActive: false },
    { id: 2, name: 'Compare TDS regime wise', path: '', isActive: true },
  ];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <BlankTemplate
      pageHeading={`Regime Wise TDS Workings: ${empName} ${empId ? ` (${empId})` : ''}`}
      breadcrumbData={breadcrumbData}
    >
      <CompareTDS
        empName={empName}
        empId={empId}
        categoryColumnsData={categoryColumnsData}
        categoryRowsData={categoryRowsData}
        amountData={amountData}
        hraAmount={hraAmount}
        currencySymbol={currencySymbol}
        totalRentPaid={totalRentPaid}
        taxOnEmployment={taxOnEmployment}
        deductionTableData={deductionTableData}
        totalDeductionAmount={totalDeductionAmount}
        hraData={hraData}
        incomeData={incomeData}
        employerNames={employerNames}
        statutoryData={statutoryData}
        totalExemptionPrice={totalExemptionPrice}
        startDate={startDate}
        endDate={endDate}
        basicPerc={basicPerc}
        standardDeduction={standardDeduction}
        ref={componentRef}
        handlePrint={handlePrint}
      />
    </BlankTemplate>
  );
};

CompareTDSTemplateTemplate.defaultProps = {
  tdsWorkingProfile: {},
  empName: '',
  empId: '',
  categoryColumnsData: [],
  categoryRowsData: [],
  amountData: {},
  hraAmount: '',
  totalRentPaid: '',
  taxOnEmployment: '',
  deductionTableData: [],
  totalDeductionAmount: '',
  hraData: [],
  incomeData: [],
  statutoryData: [],
  totalExemptionPrice: '',
  startDate: '',
  endDate: '',
  basicPerc: '',
  standardDeduction: '',
  employerNames: [],
};

CompareTDSTemplateTemplate.propTypes = {
  tdsWorkingProfile: {},
  statutoryData: PropTypes.arrayOf({}),
  totalExemptionPrice: PropTypes.string,
  empName: PropTypes.string,
  empId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  categoryColumnsData: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  categoryRowsData: PropTypes.shape({
    'Professional Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Income Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Total Deductions': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Net Earnings': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
  }),
  amountData: PropTypes.shape(PropTypes.shape({})),
  hraAmount: PropTypes.string,
  totalRentPaid: PropTypes.string,
  taxOnEmployment: PropTypes.string,
  deductionTableData: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.string,
      investmentProposed: PropTypes.string,
      investmentActual: PropTypes.string,
      sectionLimit: PropTypes.string,
      deductionAmount: PropTypes.string,
    }),
  ),
  totalDeductionAmount: PropTypes.string,
  hraData: PropTypes.arrayOf(
    PropTypes.shape({
      received: PropTypes.string,
      basic: PropTypes.string,
      rentPaid: PropTypes.string,
    }),
  ),
  incomeData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        amount: PropTypes.string,
      }),
    ),
  ),
  basicPerc: PropTypes.string,
  standardDeduction: PropTypes.string,
  employerNames: PropTypes.arrayOf(PropTypes.string),
};

export default CompareTDSTemplateTemplate;
