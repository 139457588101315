import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme, Alert } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Button from 'components/Atoms/Button';
import DropdownMenu from 'components/Molecules/DropdownMenu';
import EditIcon from '@mui/icons-material/Edit';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { useSelector } from 'react-redux';
import { hasPermission } from 'utils/permissions';

const TDSBasicDetails = ({
  menuLists,
  basicDetails,
  handleEdit,
  allowEdit,
  employeeDeductionDeclarationInstance,
  isNewTaxRegime,
}) => {
  const theme = useTheme();
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasRightDeclarationSubmissionStatus =
    employeeDeductionDeclarationInstance?.declarationSubmissionStatus === 'Draft Declaration' ||
    employeeDeductionDeclarationInstance?.declarationSubmissionStatus === 'Declaration Under Review';
  const isPayrollOpenForDeclaration =
    employeeDeductionDeclarationInstance?.declarationStatus === 'Open for Declaration';
  const userHasEditPermisson =
    hasPermission(authorities, ['ROLE_BASE_PERMISSION_PAY_INDEX_UPDATE_MY_DECLARATION']) &&
    isPayrollOpenForDeclaration &&
    userHasRightDeclarationSubmissionStatus;
  const menuListCount = menuLists.reduce((acc, element) => acc + !!element, 0);
  return (
    <Box sx={tdsStyle.basicDetailsWrapper}>
      {!!isNewTaxRegime && (
        <Alert severity="error" sx={{ marginBottom: '1.8rem' }}>
          Investment Declaration is not required as you have opted for New Tax Regime.
        </Alert>
      )}
      {!allowEdit && (
        <Box sx={tdsStyle.actionBtn}>
          {!!userHasEditPermisson && (
            <Button startIcon={<EditIcon fontSize="2.5rem" />} onClick={handleEdit}>
              Edit
            </Button>
          )}
          {!!menuListCount && <DropdownMenu menuLists={menuLists} btnLabel="Actions" color="primary" />}
        </Box>
      )}
      <Box mt={2}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Employee Name*" text={basicDetails.employeeName} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Employee ID*" text={basicDetails.employeeID} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Designation *" text={basicDetails.designation} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Department *" text={basicDetails.department} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Financial Year*" text={basicDetails.financialYear} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Assessment Year *" text={basicDetails.assessmentYear} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="PAN Number *" text={basicDetails.panNumber} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Aadhaar Number*" text={basicDetails.aadhaarNumber} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText
              title="Date of Joining*"
              text={validateDateElseHyphen(basicDetails.dateOfJoining, 'DD MMMM YYYY')}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 1, borderColor: theme.palette.other[200] }} />
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <TitleWithText title="Declaration Submissions" text={basicDetails.submissionsDeclaration} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText
              title="Action Owner"
              text={
                basicDetails.submissionsActionOwner !== '-' && basicDetails.submissionsActionOwnerId !== '-'
                  ? `${basicDetails.submissionsActionOwner} (${basicDetails.submissionsActionOwnerId})`
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TitleWithText title="Investment/Rental Proof Submission Allowed?" text={basicDetails.submissionAllowed} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText title="Proof Submission Status" text={basicDetails.proofSubmissionStatus} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TitleWithText
              title="Action Owner"
              text={
                basicDetails.proofSubmissionActionOwner !== '-' && basicDetails.proofSubmissionActionOwnerId !== '-'
                  ? `${basicDetails.proofSubmissionActionOwner} (${basicDetails.proofSubmissionActionOwnerId})`
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TitleWithText title="Approved Amount Updation Status" text={basicDetails.approvedAmountStatus} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

TDSBasicDetails.defaultProps = {
  basicDetails: {},
  handleEdit: () => {},
  menuLists: [],
  allowEdit: false,
};

TDSBasicDetails.propTypes = {
  basicDetails: PropTypes.shape({
    employeeName: PropTypes.string,
    employeeID: PropTypes.string,
    designation: PropTypes.string,
    department: PropTypes.string,
    financialYear: PropTypes.string,
    assessmentYear: PropTypes.string,
    panNumber: PropTypes.string,
    aadhaarNumber: PropTypes.string,
    dateOfJoining: PropTypes.string,
    submissionsDeclaration: PropTypes.string,
    submissionsStatus: PropTypes.string,
    submissionsActionOwner: PropTypes.string,
    submissionsActionOwnerId: PropTypes.string,
    submissionAllowed: PropTypes.string,
    proofSubmissionStatus: PropTypes.string,
    proofSubmissionActionOwner: PropTypes.string,
    proofSubmissionActionOwnerId: PropTypes.string,
    approvedAmountStatus: PropTypes.string,
  }),
  handleEdit: PropTypes.func,
  menuLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  allowEdit: PropTypes.bool,
};

export default TDSBasicDetails;
