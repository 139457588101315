import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import MuiTab from 'components/Molecules/Tab';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MoneyIcon from '@mui/icons-material/Money';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SalaryDetails from 'components/Organisms/MyPay/SalaryDetails';
import SalarySlipList from 'components/Organisms/MyPay/SalarySlips/List';
import TDSWorking from 'components/Organisms/MyPay/TDSWorking';
import DocumentsTab from '../../HomeDashboard/TabCard/DocumentsTab';
import FnfDetails from '../FnfDetails';
import { alumniDashboardStyle } from '../style';

const TabCard = ({
  configuredFnfDetails,
  configuredTDSData,
  configuredSalaryDetails,
  salaryDetailsColumns,
  salarySlipDataColumn,
  salarySlipDataRow,
  onSalarySlipRowRowClick,
  configuredDocumentDetails,
  userHasViewFnFStatementPermission,
  userHasPrintSalarySlipPermission,
  userHasViewDocumentListPermission,
}) => {
  const initialTabs = [
    {
      icon: <ChangeHistoryIcon />,
      iconPosition: 'start',
      label: 'My Salary Details',
      RenderComponent: SalaryDetails,
      componentProps: {
        employeeData: configuredSalaryDetails?.employeeData,
        employeeId: configuredSalaryDetails?.employeeId,
        employeeName: configuredSalaryDetails?.employeeName,
        earningsColumns: salaryDetailsColumns?.earningsColumns,
        earningsRows: configuredSalaryDetails?.earningsRows,
        variablePayColumns: salaryDetailsColumns?.variablePayColumns,
        variablePayRows: configuredSalaryDetails?.variablePayRows,
        statutoryDeductionsColumns: salaryDetailsColumns?.statutoryDeductionsColumns,
        statutoryDeductionsRows: configuredSalaryDetails?.statutoryDeductionsRows,
        employerRetiralColumns: salaryDetailsColumns?.employerRetiralColumns,
        employerRetiralRows: configuredSalaryDetails?.employerRetiralRows,
        fixedComponentsColumns: salaryDetailsColumns?.fixedComponentsColumns,
        fixedComponentsRows: configuredSalaryDetails?.fixedComponentsRows,
        netSalaryColumns: salaryDetailsColumns?.netSalaryColumns,
        netSalaryRows: configuredSalaryDetails?.netSalaryRows,
        netVariablePayColumns: salaryDetailsColumns?.netVariablePayColumns,
        netVariablePayRows: configuredSalaryDetails?.netVariablePayRows,
        retiralColumns: salaryDetailsColumns?.retiralColumns,
        retiralRows: configuredSalaryDetails?.retiralRows,
        totalColumns: salaryDetailsColumns?.totalColumns,
        totalRows: configuredSalaryDetails?.totalRows,
        isOverTimeApplicable: configuredSalaryDetails?.isOverTimeApplicable,
        benefitsRows: configuredSalaryDetails?.benefitsRows,
        benifitColumns: salaryDetailsColumns?.benefitsColumns,
        reimbursementsRows: configuredSalaryDetails?.reimbursementsRows,
        reimbursementColumns: salaryDetailsColumns?.reimbursementsColumns,
      },
      userHasPermission: true,
    },
    {
      icon: <MarkEmailUnreadIcon />,
      iconPosition: 'start',
      label: 'Salary Slips',
      RenderComponent: SalarySlipList,
      componentProps: {
        salarySlipDataColumn,
        salarySlipDataRow,
        onRowClick: onSalarySlipRowRowClick,
      },
      userHasPermission: true,
    },
    {
      icon: <MoneyIcon />,
      iconPosition: 'start',
      label: 'TDS Workings',
      RenderComponent: TDSWorking,
      componentProps: {
        empName: configuredTDSData?.empName,
        empId: configuredTDSData?.empId,
        categoryColumnsData: configuredTDSData?.categoryColumnsData,
        categoryRowsData: configuredTDSData?.categoryRowsData,
        amountData: configuredTDSData?.amountData,
        hraAmount: configuredTDSData?.hraAmount,
        totalRentPaid: configuredTDSData?.totalRentPaid,
        standardDeduction: configuredTDSData?.standardDeduction,
        currentFinancialYear: configuredTDSData?.currentFinancialYear,
        taxOnEmployment: configuredTDSData?.taxOnEmployment,
        deductionTableData: configuredTDSData?.deductionTableData,
        totalDeductionAmount: configuredTDSData?.totalDeductionAmount,
        rowEarnings: configuredTDSData?.rowEarnings,
        columnEarnings: configuredTDSData?.columnEarnings,
        rowDeductions: configuredTDSData?.rowDeductions,
        columnDeductions: configuredTDSData?.columnDeductions,
        rowOther: configuredTDSData?.rowOther,
        columnOther: configuredTDSData?.columnOther,
        rowRetirals: configuredTDSData?.rowRetirals,
        columnRetirals: configuredTDSData?.columnRetirals,
        hraData: configuredTDSData?.hraData,
        rowExemption: configuredTDSData?.rowExemption,
        columnExemption: configuredTDSData?.columnExemption,
        startDate: configuredTDSData?.startDate,
        endDate: configuredTDSData?.endDate,
      },
      userHasPermission: true,
    },
    {
      icon: <VerifiedUserIcon />,
      iconPosition: 'start',
      label: 'FnF Slip',
      RenderComponent: FnfDetails,
      componentProps: {
        fnfSalSlipId: configuredFnfDetails?.fnfSalSlipId,
        employeeData: configuredFnfDetails?.employeeData,
        employeeName: configuredFnfDetails?.employeeName,
        employeeId: configuredFnfDetails?.employeeId,
        netFinalEarnings: configuredFnfDetails?.netFinalEarnings,
        netFinalDeductions: configuredFnfDetails?.netFinalDeductions,
        netFinalSalary: configuredFnfDetails?.netFinalSalary,
        earningsRows: configuredFnfDetails?.earningsRows,
        earningsColumns: configuredFnfDetails?.earningsColumns,
        deductionsRows: configuredFnfDetails?.deductionsRows,
        deductionsColumns: configuredFnfDetails?.deductionsColumns,
        loansAndAdvancesRows: configuredFnfDetails?.loansAndAdvancesRows,
        loansAndAdvancesColumns: configuredFnfDetails?.loansAndAdvancesColumns,
        grossEarningsColumn: configuredFnfDetails?.grossEarningsColumn,
        totalDeductionsColumn: configuredFnfDetails?.totalDeductionsColumn,
        netSalaryColumns: configuredFnfDetails?.netSalaryColumns,
        fnfRemarks: configuredFnfDetails?.fnfRemarks,
        userHasPrintSalarySlipPermission,
      },
      userHasPermission: userHasViewFnFStatementPermission,
    },
    {
      icon: <AssignmentIcon />,
      iconPosition: 'start',
      label: 'Documents',
      RenderComponent: DocumentsTab,
      componentProps: {
        loading: configuredDocumentDetails?.documentsLoading,
        documentsColumn: configuredDocumentDetails?.documentsColumn,
        documentsRow: configuredDocumentDetails?.documentsRow,
        sortDirection: configuredDocumentDetails?.documentsSortDirection,
        sortBy: configuredDocumentDetails?.documentsSortBy,
        onSortChange: configuredDocumentDetails?.documentsOnSortChange,
        count: configuredDocumentDetails?.documentsCount,
        page: configuredDocumentDetails?.documentsPage,
        onPageChange: configuredDocumentDetails?.documentsOnPageChange,
        handleDownload: configuredDocumentDetails?.documentsHandleDownload,
        docFilterLabel: configuredDocumentDetails?.docFilterLabel,
        docFilterOptions: configuredDocumentDetails?.docFilterOptions,
        handleDocSelect: configuredDocumentDetails?.handleDocSelect,
      },
      userHasPermission: userHasViewDocumentListPermission,
    },
  ];

  const filteredTabsWithPermission = initialTabs?.filter((item) => item?.userHasPermission === true);

  return (
    <Box sx={alumniDashboardStyle.tabCardWrapper}>
      <MuiTab
        sx={{
          '.MuiTabs-vertical': {
            '& .MuiTabs-flexContainerVertical': {
              minWidth: '22rem !important',
              maxWidth: '25rem !important',
            },
          },
        }}
        orientation="vertical"
        textColor="primary"
        indicatorColor="primary"
        isVertical
        panelPadding="2.5rem 3rem"
        tabs={filteredTabsWithPermission}
      />
    </Box>
  );
};

TabCard.defaultProps = {
  userHasViewFnFStatementPermission: false,
  userHasPrintSalarySlipPermission: false,
  userHasViewDocumentListPermission: false,
  configuredFnfDetails: {},
  salaryDetailsColumns: {},
  configuredSalaryDetails: {},
  salarySlipDataColumn: [],
  salarySlipDataRow: [],
  onSalarySlipRowRowClick: () => {},
  documentsLoading: false,
  documentsColumn: [],
  documentsRow: [],
  documentsSortDirection: 'asc',
  documentsSortBy: 'id',
  documentsOnSortChange: () => {},
  documentsCount: 7,
  documentsPage: 0,
  documentsOnPageChange: () => {},
  documentsHandleDownload: () => {},
  docFilterLabel: '',
  docFilterOptions: [],
  handleDocSelect: () => {},
  configuredDocumentDetails: {},
  configuredTDSData: {},
};

TabCard.propTypes = {
  userHasViewFnFStatementPermission: PropTypes.bool,
  userHasPrintSalarySlipPermission: PropTypes.bool,
  userHasViewDocumentListPermission: PropTypes.bool,
  configuredFnfDetails: PropTypes.shape({}),
  salaryDetailsColumns: PropTypes.shape({}),
  configuredSalaryDetails: PropTypes.shape({}),
  salarySlipDataColumn: PropTypes.arrayOf({}),
  salarySlipDataRow: PropTypes.arrayOf({}),
  onSalarySlipRowRowClick: PropTypes.func,
  documentsLoading: PropTypes.bool,
  documentsColumn: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
      formatValue: PropTypes.func,
    }),
  ),
  documentsRow: PropTypes.arrayOf(
    PropTypes.shape({
      slNo: PropTypes.number,
      documentName: PropTypes.string,
      section: PropTypes.string,
    }),
  ),
  documentsSortDirection: PropTypes.string,
  documentsSortBy: PropTypes.string,
  documentsOnSortChange: PropTypes.func,
  documentsCount: PropTypes.number,
  documentsPage: PropTypes.number,
  documentsOnPageChange: PropTypes.func,
  documentsHandleDownload: PropTypes.func,
  docFilterLabel: PropTypes.string,
  docFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  handleDocSelect: PropTypes.func,
  configuredDocumentDetails: PropTypes.shape({}),
  configuredTDSData: PropTypes.shape({}),
};

export default TabCard;
