import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'components/Atoms/Button';
import InputField from 'components/Atoms/InputField/index';
import { useFormik } from 'formik';
import Dropdown from 'components/Molecules/Dropdown';
import DatePicker from 'components/Atoms/Datepicker';
import Typographys from 'components/Atoms/Typography';
import Attachments from 'components/Atoms/Attachments';
import * as yup from 'yup';
import { leaveStyle } from 'components/Organisms/Leave/style';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { checkProofEligibility } from './checkProofEligibility';

const LeaveRequestForm = ({
  handleSubmit,
  leaveFormData,
  leaveCategoryOptions,
  leaveTypeOptions,
  preModifyData,
  handleDraft,
  handleDelete,
  isEdit,
  handleCancel,
  loading,
}) => {
  const [showFields, setShowFields] = useState({
    endDate: true,
    numberOfHours: false,
  });
  const [message, setMessage] = useState('');
  const [workingDayMessage, setWorkingDayMessage] = useState('');
  const [attachmentRequiredAsterisk, setAttachmentRequiredAsterisk] = useState(false);
  const [isSandwichRuleApplicable, setIsSandwichRuleApplicable] = useState(0);
  const [executionFlag, setExecutionFlag] = useState(false);

  const {
    catLeaveBalMap,
    catMaxLeaveApplMap,
    catMaxLeaveCapMap,
    catAllowNegativeBalanceMap,
    catProofApplMap,
    catSandwichApplMap,
    catEnforceMonthlyLimitsMap,
    catConsiderNegativeBalanceAsLOPMap,
    catMinimumNumberOfDaysWorkedMap,
    catHourlyTimeOffApplMap,
    catHalfDayTimeOffApplMap,
    catProofThresholdApplMap,
    catMinDaysProofThresholdMap,
    catFrequencyCapApplMap,
    catMaxDaysPerCapPeriodMap,
    catFrequencyCapPeriodMap,
    workingDaysList,
    catIDWiseMonthTransac,
    tenureInDays,
    catIDWiseAnnualTransac,
    catIsMinDaysApplMap,
    catIDWiseQuarterTransac,
    holidayEpochsList,
    nonWorkingDaysList,
    catMinDaysPerReqMap,
    additionalWeeklyOffDaysEpoch,
    standardWorkingHoursPerDay,
    jsonObj,
  } = preModifyData;

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  let transactionPermitted = true;

  const defaultValues = leaveFormData || {
    leaveCategory: '',
    leaveType: '',
    startDate: '',
    endDate: '',
    numberOfDays: '',
    numberOfHours: '',
    description: '',
    attachments: '',
  };

  const LeaveFormSchema = yup.object().shape({
    leaveCategory: yup.string().required('Required'),
    leaveType: yup.string().required('Required'),
    startDate: yup.string().required('Required'),
    endDate: yup.string(),
    description: yup.string().required('Required'),
  });

  const canSubmitDetails = (values, triggerPoint) => {
    transactionPermitted = true;
    const leaveType = values.leaveType;
    setMessage('');
    const catID = values.leaveCategory;
    let categoryBalance = 0;
    let dayCount = 0;
    let catBalancePostTransaction = 0;
    const numDaysElem = values.numberOfDays;
    if (numDaysElem) dayCount = numDaysElem;

    if (catID && catLeaveBalMap[catID]) categoryBalance = catLeaveBalMap[catID];
    catBalancePostTransaction = categoryBalance - dayCount;
    let categoryMaxCapAppl = false;
    let categoryMaxCap = 0;
    let categorySandwichRuleAppl = false;
    let categoryProofAppl = false;
    let categoryHourlyTimeOffAppl = false;
    let categoryHalfDayTimeOffAppl = false;

    let catAllowNegativeBalance = false;
    let catConsiderNegativeBalanceAsLOP = false;
    let catEnforceMonthlyLimits = false;
    let catMinimumNumberOfDaysWorked = 0.0;

    let leavePermittedUnderLeaveType = true;

    let isProofThresholdRequired = false;
    let minDaysForForProofThreshold = 0.0;

    let isFrequencyCapApplicable = false;
    let maxDaysCapPerPeriod = 0.0;
    let frequencyCapPeriod = null;

    let numTransacMonth = 0.0;
    let numTransacQuarter = 0.0;
    let numTransacYear = 0.0;

    let frequencyCapPerMonthExceeded = false;
    let frequencyCapPerQuarterExceeded = false;
    let frequencyCapPerAnnumExceeded = false;

    let isMinNumDaysApplicable = false;
    let minNumDaysPerRequest = 0.0;

    let minDayReqtMet = true;

    if (catID && catMaxLeaveApplMap[catID]) categoryMaxCapAppl = catMaxLeaveApplMap[catID];
    if (catID && catMaxLeaveCapMap[catID]) categoryMaxCap = catMaxLeaveCapMap[catID];
    if (catID && catSandwichApplMap[catID]) categorySandwichRuleAppl = catSandwichApplMap[catID];
    if (catID && catProofApplMap[catID]) categoryProofAppl = catProofApplMap[catID];

    if (catID && catAllowNegativeBalanceMap[catID]) catAllowNegativeBalance = catAllowNegativeBalanceMap[catID];
    if (catID && catConsiderNegativeBalanceAsLOPMap[catID])
      catConsiderNegativeBalanceAsLOP = catConsiderNegativeBalanceAsLOPMap[catID];

    if (catID && catEnforceMonthlyLimitsMap[catID]) catEnforceMonthlyLimits = catEnforceMonthlyLimitsMap[catID];
    if (catID && catMinimumNumberOfDaysWorkedMap[catID])
      catMinimumNumberOfDaysWorked = catMinimumNumberOfDaysWorkedMap[catID];

    if (catID && catHourlyTimeOffApplMap[catID]) categoryHourlyTimeOffAppl = catHourlyTimeOffApplMap[catID];
    if (catID && catHalfDayTimeOffApplMap[catID]) categoryHalfDayTimeOffAppl = catHalfDayTimeOffApplMap[catID];

    if (catID && catProofThresholdApplMap[catID]) isProofThresholdRequired = catProofThresholdApplMap[catID];

    if (catID && catMinDaysProofThresholdMap[catID]) minDaysForForProofThreshold = catMinDaysProofThresholdMap[catID];

    if (catID && catFrequencyCapApplMap[catID]) isFrequencyCapApplicable = catFrequencyCapApplMap[catID];
    if (catID && catMaxDaysPerCapPeriodMap[catID]) maxDaysCapPerPeriod = catMaxDaysPerCapPeriodMap[catID];
    if (catID && catFrequencyCapPeriodMap[catID]) frequencyCapPeriod = catFrequencyCapPeriodMap[catID];

    if (catID && catIDWiseMonthTransac[catID]) numTransacMonth = catIDWiseMonthTransac[catID];

    if (catID && catIDWiseQuarterTransac[catID]) numTransacQuarter = catIDWiseQuarterTransac[catID];

    if (catID && catIDWiseAnnualTransac[catID]) numTransacYear = catIDWiseAnnualTransac[catID];

    if (catID && catIsMinDaysApplMap[catID]) isMinNumDaysApplicable = catIsMinDaysApplMap[catID];

    if (catID && catMinDaysPerReqMap[catID]) minNumDaysPerRequest = catMinDaysPerReqMap[catID];

    if (isFrequencyCapApplicable && frequencyCapPeriod != null) {
      if (maxDaysCapPerPeriod !== 0) {
        if (frequencyCapPeriod.localeCompare('Per Month') === 0) {
          if (numTransacMonth !== 0) {
            const postAddCount = numTransacMonth + 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerMonthExceeded = true;
          } else {
            const postAddCount = 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerMonthExceeded = true;
          }
        } else if (frequencyCapPeriod.localeCompare('Per Quarter') === 0) {
          if (numTransacQuarter !== 0) {
            const postAddCount = numTransacQuarter + 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerQuarterExceeded = true;
          } else {
            const postAddCount = 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerQuarterExceeded = true;
          }
        } else if (frequencyCapPeriod.localeCompare('Per Annum') === 0) {
          if (numTransacYear !== 0) {
            const postAddCount = numTransacYear + 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerAnnumExceeded = true;
          } else {
            const postAddCount = 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerAnnumExceeded = true;
          }
        }
      }
    }

    if (isMinNumDaysApplicable && minNumDaysPerRequest !== 0) {
      if (dayCount < minNumDaysPerRequest) minDayReqtMet = false;
    }

    let isFullDayLeaves = false; // true;
    let isHalfDayLeave = false;
    let isPartialLeave = false;

    if (leaveType === 'Full Day Leave') isFullDayLeaves = true;
    else if (leaveType === 'Half Day Leave - 1st Half' || leaveType === 'Half Day Leave - 2nd Half')
      isHalfDayLeave = true;
    else if (leaveType === 'Partial Day Leave') isPartialLeave = true;

    if (!categoryHalfDayTimeOffAppl && isHalfDayLeave) leavePermittedUnderLeaveType = false;
    if (!categoryHourlyTimeOffAppl && isPartialLeave) leavePermittedUnderLeaveType = false;
    // Check for negative balance
    let errorMsg = '';
    if (!leavePermittedUnderLeaveType) {
      if (isHalfDayLeave) {
        errorMsg += `<i style='color: RED'>ERROR: Half Day Leaves not permitted for the selected Leave Category.
           Please consider applying under other leave categories.</i><br/>`;
        transactionPermitted = false;
      }
      if (isPartialLeave) {
        errorMsg += `<i style='color: RED'>ERROR: Partial Day Leaves not permitted for the selected Leave Category.
           Please consider applying under other leave categories.</i><br/>`;
        transactionPermitted = false;
      }
    }
    if (frequencyCapPerMonthExceeded) {
      transactionPermitted = false;
      errorMsg = `${errorMsg}<i style='color: RED'>ERROR: You cannot apply for leaves under this category as you have already applied 
      the max permittable limit per month: <b>${maxDaysCapPerPeriod}</b></i><br/>`;
    }
    if (frequencyCapPerQuarterExceeded) {
      transactionPermitted = false;
      errorMsg = `${errorMsg}<i style='color: RED'>ERROR: You cannot apply for leaves under this category as you have
       already applied the max permittable limit per quarter: <b>${maxDaysCapPerPeriod}</b></i><br/>`;
    }
    if (frequencyCapPerAnnumExceeded) {
      transactionPermitted = false;
      errorMsg = `${errorMsg}<i style='color: RED'>ERROR: You cannot apply for leaves under this category as you have
       already applied the max permittable limit per year: <b>${maxDaysCapPerPeriod}</b></i><br/>`;
    }
    if (dayCount === 0) {
      transactionPermitted = false;
      errorMsg += '<i style="color: RED">ERROR: Transaction Not Permitted with zero days. </i><br/>';
    }
    if (!catAllowNegativeBalance && catBalancePostTransaction < 0) {
      transactionPermitted = false;
      errorMsg += `<i style="color: RED">ERROR: Transaction Not Permitted as Negative Balance is
         not allowed under this Leave Category. </i><br/>`;
    }
    // Check for minimum Num days employed
    if (catMinimumNumberOfDaysWorked > 0 && catMinimumNumberOfDaysWorked > tenureInDays) {
      transactionPermitted = false;
      errorMsg += `<i style='color: RED'>ERROR: Transaction Not Permitted as current tenure
       is Less than Minimum Number of days
         required for eligibility under this Leave Category. </i><br/>`;
    }

    const proofEligibilityCheck = checkProofEligibility(
      values,
      catProofApplMap,
      catMinDaysProofThresholdMap,
      catProofThresholdApplMap,
      triggerPoint,
      errorMsg,
      transactionPermitted,
    );

    if (proofEligibilityCheck.transactionPermitted === false) transactionPermitted = false;
    if (proofEligibilityCheck.attachmentRequiredAsterisk === true) {
      setAttachmentRequiredAsterisk(true);
    } else {
      setAttachmentRequiredAsterisk(false);
    }
    errorMsg = proofEligibilityCheck.errorMsg;

    if (categoryMaxCapAppl && dayCount > categoryMaxCap) {
      transactionPermitted = false;
      errorMsg = `${errorMsg}<i style='color: RED'>ERROR: Chosen Leave Category has a Maximum
       Leaves Block cap of ${categoryMaxCap} Days.</i><br/>`;
    }

    if (!minDayReqtMet) {
      transactionPermitted = false;
      errorMsg = `${errorMsg}<i style='color: RED'>ERROR: Chosen Leave Category has a Minimum Leaves
       Requirement of ${minNumDaysPerRequest} Days.</i><br/>`;
    }
    if (!transactionPermitted) {
      setMessage(errorMsg);
    } else {
      setMessage('');
    }
    return transactionPermitted;
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: LeaveFormSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (canSubmitDetails(values, 'buttonClick')) {
        handleSubmit({ ...values, isSandwichRuleApplicable });
      }
    },
  });

  const attachmentRequired = formik?.values?.leaveCategory && catProofApplMap[formik?.values?.leaveCategory];

  function workingDaysBetweenDates(startDate, endDate, catID, leaveType) {
    setWorkingDayMessage('');
    // Validate input
    if (endDate && startDate) {
      if (endDate < startDate) return 0;
    }

    const halfDayNotApplMessage = `<p style='color: RED'>Half Day Leaves not permitted for the selected Leave Category.
     Please consider applying under other leave categories.</p>`;
    const partialDayNotApplMessage = `<p style='color: RED'>
    Partial Day Leaves not permitted for the selected Leave Category.
     Please consider applying under other leave categories.</p>`;

    let lopMessage = `<p style='color: RED'>Leave claimed is more than available balance.
     Might result in Loss of Pay (LOP). 
     Please consider applying excess leave under other leave categories to avoid LOP.</p>`;
    let holidaysMessage = '<i>Following public holidays found during the selected period: </i>';

    const monthFreqCapMsgBegin = `<p style='color: RED'>
    You cannot apply for leaves under this category as you
     have already applied the max permittable limit per month: <b><i>`;
    const monthFreqCapMsgEnd = '</i></b></p>';

    const quarterFreqCapMsgBegin = `<p style='color: RED'>
    You cannot apply for leaves under this category as you have
     already applied the max permittable limit per quarter: <b><i>`;
    const quarterFreqCapMsgEnd = '</i></b></p>';

    const annualFreqCapMsgBegin = `<p style='color: RED'>
    You cannot apply for leaves under this category as you have
     already applied the max permittable limit per year: <b><i>`;
    const annualFreqCapMsgEnd = '</i></b></p>';

    const monthMinReqMsgBegin = "<p style='color: RED'>You need to apply for atleast <b><i>";
    const monthMinReqMsgEnd = '</i></b> leaves under this category.</p>';

    let categoryBalance = 0;
    if (catID && catLeaveBalMap[catID]) categoryBalance = catLeaveBalMap[catID];
    let categoryMaxCapAppl = false;
    let categoryMaxCap = 0;
    let categorySandwichRuleAppl = false;
    let categoryProofAppl = false;
    let categoryHourlyTimeOffAppl = false;
    let categoryHalfDayTimeOffAppl = false;

    let catAllowNegativeBalance = false;
    let catConsiderNegativeBalanceAsLOP = false;
    let catEnforceMonthlyLimits = false;
    let catMinimumNumberOfDaysWorked = 0.0;

    let leavePermittedUnderLeaveType = true;

    let isProofThresholdRequired = false;
    let minDaysForForProofThreshold = 0.0;

    let isFrequencyCapApplicable = false;
    let maxDaysCapPerPeriod = 0.0;
    let frequencyCapPeriod = null;

    let numTransacMonth = 0.0;
    let numTransacQuarter = 0.0;
    let numTransacYear = 0.0;

    let frequencyCapPerMonthExceeded = false;
    let frequencyCapPerQuarterExceeded = false;
    let frequencyCapPerAnnumExceeded = false;

    let isMinNumDaysApplicable = false;
    let minNumDaysPerRequest = 0.0;

    let minDayReqtMet = true;

    if (catID && catMaxLeaveApplMap[catID]) categoryMaxCapAppl = catMaxLeaveApplMap[catID];
    if (catID && catMaxLeaveCapMap[catID]) categoryMaxCap = catMaxLeaveCapMap[catID];
    if (catID && catSandwichApplMap[catID]) categorySandwichRuleAppl = catSandwichApplMap[catID];

    if (categorySandwichRuleAppl) setIsSandwichRuleApplicable(1);

    if (catID && catProofApplMap[catID]) categoryProofAppl = catProofApplMap[catID];

    if (catID && catAllowNegativeBalanceMap[catID]) catAllowNegativeBalance = catAllowNegativeBalanceMap[catID];
    if (catID && catConsiderNegativeBalanceAsLOPMap[catID])
      catConsiderNegativeBalanceAsLOP = catConsiderNegativeBalanceAsLOPMap[catID];

    if (catID && catEnforceMonthlyLimitsMap[catID]) catEnforceMonthlyLimits = catEnforceMonthlyLimitsMap[catID];
    if (catID && catMinimumNumberOfDaysWorkedMap[catID])
      catMinimumNumberOfDaysWorked = catMinimumNumberOfDaysWorkedMap[catID];

    if (catID && catHourlyTimeOffApplMap[catID]) categoryHourlyTimeOffAppl = catHourlyTimeOffApplMap[catID];
    if (catID && catHalfDayTimeOffApplMap[catID]) categoryHalfDayTimeOffAppl = catHalfDayTimeOffApplMap[catID];

    if (catID && catProofThresholdApplMap[catID]) isProofThresholdRequired = catProofThresholdApplMap[catID];

    if (catID && catMinDaysProofThresholdMap[catID]) minDaysForForProofThreshold = catMinDaysProofThresholdMap[catID];

    if (catID && catFrequencyCapApplMap[catID]) isFrequencyCapApplicable = catFrequencyCapApplMap[catID];
    if (catID && catMaxDaysPerCapPeriodMap[catID]) maxDaysCapPerPeriod = catMaxDaysPerCapPeriodMap[catID];
    if (catID && catFrequencyCapPeriodMap[catID]) frequencyCapPeriod = catFrequencyCapPeriodMap[catID];

    if (catID && catIDWiseMonthTransac[catID]) numTransacMonth = catIDWiseMonthTransac[catID];

    if (catID && catIDWiseQuarterTransac[catID]) numTransacQuarter = catIDWiseQuarterTransac[catID];

    if (catID && catIDWiseAnnualTransac[catID]) numTransacYear = catIDWiseAnnualTransac[catID];

    if (catID && catIsMinDaysApplMap[catID]) isMinNumDaysApplicable = catIsMinDaysApplMap[catID];

    if (catID && catMinDaysPerReqMap[catID]) minNumDaysPerRequest = catMinDaysPerReqMap[catID];

    // TODO: Logic to be added to show or hide attachements
    // const attachDivElem = document.getElementById('attachmentDiv');
    // if (categoryProofAppl) {
    //   if (attachDivElem) attachDivElem.classList.remove('hidden');
    // } else if (attachDivElem) attachDivElem.classList.add('hidden');

    let dayCount = 0;
    let nonWorkingDaysCount = 0;
    let numHolidays = 0;
    let isFullDayLeaves = false; // true;
    let isHalfDayLeave = false;
    let isPartialLeave = false;

    if (leaveType === 'Full Day Leave') isFullDayLeaves = true;
    else if (leaveType === 'Half Day Leave - 1st Half' || leaveType === 'Half Day Leave - 2nd Half')
      isHalfDayLeave = true;
    else if (leaveType === 'Partial Day Leave') isPartialLeave = true;

    if (!categoryHalfDayTimeOffAppl && isHalfDayLeave) leavePermittedUnderLeaveType = false;
    if (!categoryHourlyTimeOffAppl && isPartialLeave) leavePermittedUnderLeaveType = false;

    if (workingDaysList.length) {
      let startDaysSinceEpoch = 0; // Math.floor(startDate/8.64e7);
      let endDaysSinceEpoch = 0; // Math.floor(endDate/8.64e7);
      if (startDate) startDaysSinceEpoch = Math.floor(startDate / 8.64e7);
      if (endDate) endDaysSinceEpoch = Math.floor(endDate / 8.64e7);
      if (isFullDayLeaves) {
        let tempDate = new Date(startDate);
        if (startDaysSinceEpoch !== endDaysSinceEpoch) {
          let itrDaysSinceEpoch = Math.floor(tempDate / 8.64e7);
          let isIterating = true;
          while (isIterating) {
            if (holidayEpochsList.includes(itrDaysSinceEpoch)) {
              holidaysMessage = `${holidaysMessage}<b>${moment(startDate).format('DD MMMM YYYY')}; `;
              numHolidays += 1;
            } else if (additionalWeeklyOffDaysEpoch.includes(itrDaysSinceEpoch)) {
              nonWorkingDaysCount += 1;
            } else {
              const itrDay = tempDate.getDay();
              if (workingDaysList.includes(itrDay)) {
                dayCount += 1;
              } else if (nonWorkingDaysList.length && nonWorkingDaysList.includes(itrDay)) {
                nonWorkingDaysCount += 1;
              }
            }
            tempDate = addDays(tempDate, 1); // tempDate.setDate(tempDate.getDate() + 1);
            itrDaysSinceEpoch = Math.floor(tempDate / 8.64e7);
            if (isEdit) {
              isIterating = itrDaysSinceEpoch < endDaysSinceEpoch;
            } else {
              isIterating = itrDaysSinceEpoch <= endDaysSinceEpoch;
            }
          }
        } else if (holidayEpochsList.includes(startDaysSinceEpoch)) {
          holidaysMessage = `${holidaysMessage}<b>${moment(startDate).format('DD MMMM YYYY')}; `;
          numHolidays += 1;
        } else if (additionalWeeklyOffDaysEpoch.includes(startDaysSinceEpoch)) {
          nonWorkingDaysCount += 1;
        } else dayCount = 1;
      } else if (isHalfDayLeave) {
        if (holidayEpochsList.includes(startDaysSinceEpoch)) {
          holidaysMessage = `${holidaysMessage}<b>${moment(startDate).format('DD MMMM YYYY')}; `;
          numHolidays += 1;
        } else if (additionalWeeklyOffDaysEpoch.includes(startDaysSinceEpoch)) {
          nonWorkingDaysCount += 1;
        } else if (startDate) {
          const itrDay = startDate.getDay();
          if (workingDaysList.includes(itrDay)) {
            dayCount += 0.5;
          } else if (nonWorkingDaysList.length && nonWorkingDaysList.includes(itrDay)) {
            nonWorkingDaysCount += 1;
          }
        }
      } else if (isPartialLeave) {
        if (holidayEpochsList.includes(startDaysSinceEpoch)) {
          holidaysMessage = `${holidaysMessage}<b>${moment(startDate).format('DD MMMM YYYY')}; `;
          numHolidays += 1;
        } else if (additionalWeeklyOffDaysEpoch.includes(startDaysSinceEpoch)) {
          let hours = 0;
          const hoursElem = formik.values.numberOfHours;
          if (hoursElem) hours = hoursElem;
          nonWorkingDaysCount = Math.round((hours / standardWorkingHoursPerDay + Number.EPSILON) * 100) / 100;
        } else {
          let hours = 0;
          const hoursElem = formik.values.numberOfHours;
          if (hoursElem) hours = hoursElem;
          dayCount = Math.round((hours / standardWorkingHoursPerDay + Number.EPSILON) * 100) / 100;
        }
      }
    }
    if (categorySandwichRuleAppl && nonWorkingDaysCount > 0) dayCount += nonWorkingDaysCount;

    // Now evaluate freuency related data
    if (isFrequencyCapApplicable && frequencyCapPeriod !== null) {
      if (maxDaysCapPerPeriod !== 0) {
        if (frequencyCapPeriod === 'Per Month') {
          if (numTransacMonth !== 0) {
            const postAddCount = numTransacMonth + 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerMonthExceeded = true;
          } else {
            const postAddCount = 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerMonthExceeded = true;
          }
        } else if (frequencyCapPeriod === 'Per Quarter') {
          if (numTransacQuarter !== 0) {
            const postAddCount = numTransacQuarter + 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerQuarterExceeded = true;
          } else {
            const postAddCount = 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerQuarterExceeded = true;
          }
        } else if (frequencyCapPeriod === 'Per Annum') {
          if (numTransacYear !== 0) {
            const postAddCount = numTransacYear + 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerAnnumExceeded = true;
          } else {
            const postAddCount = 1;
            if (postAddCount > maxDaysCapPerPeriod) frequencyCapPerAnnumExceeded = true;
          }
        }
      }
    }

    if (isMinNumDaysApplicable && minNumDaysPerRequest !== 0) {
      if (dayCount < minNumDaysPerRequest) minDayReqtMet = false;
    }

    if (
      numHolidays !== 0 ||
      dayCount > categoryBalance ||
      (categorySandwichRuleAppl && nonWorkingDaysCount > 0) ||
      !leavePermittedUnderLeaveType ||
      frequencyCapPerMonthExceeded ||
      frequencyCapPerQuarterExceeded ||
      frequencyCapPerAnnumExceeded ||
      !minDayReqtMet
    ) {
      let msgLC = '';
      if (numHolidays !== 0) msgLC = holidaysMessage;
      if (!leavePermittedUnderLeaveType) {
        if (isHalfDayLeave) msgLC += halfDayNotApplMessage;
        if (isPartialLeave) msgLC += partialDayNotApplMessage;
      }
      if (frequencyCapPerMonthExceeded) {
        msgLC = msgLC + monthFreqCapMsgBegin + maxDaysCapPerPeriod + monthFreqCapMsgEnd;
      }
      if (frequencyCapPerQuarterExceeded) {
        msgLC = msgLC + quarterFreqCapMsgBegin + maxDaysCapPerPeriod + quarterFreqCapMsgEnd;
      }
      if (frequencyCapPerAnnumExceeded) {
        msgLC = msgLC + annualFreqCapMsgBegin + maxDaysCapPerPeriod + annualFreqCapMsgEnd;
      }

      if (!minDayReqtMet) {
        msgLC = msgLC + monthMinReqMsgBegin + minNumDaysPerRequest + monthMinReqMsgEnd;
      }
      if (dayCount > categoryBalance) {
        const lopCount = dayCount - categoryBalance;
        lopMessage = `<p style='color: RED'><i>
        Leave claimed is more than available balance. Might result in Loss of Pay of 
        <b>${lopCount}</b> days. Please consider applying excess leave under other leave categories to avoid LOP.
        </p></i></p>`;
        msgLC += lopMessage;
      }
      if (categorySandwichRuleAppl && nonWorkingDaysCount > 0) {
        msgLC = `${msgLC}<p style='color: RED'>
        <i>Sandwich Rule Applicable: Number of days additionally included: <b>${nonWorkingDaysCount}</b> </i></p>`;
      }
      setWorkingDayMessage(msgLC);
    }
    return dayCount;
  }

  const getDaysUsingDates = (startDate, endDate) => {
    if (!startDate || !formik.values.leaveCategory || !formik.values.leaveType) return 0;
    const days = workingDaysBetweenDates(
      new Date(startDate),
      new Date(endDate),
      formik.values.leaveCategory,
      formik.values.leaveType,
    );
    formik.setFieldValue('numberOfDays', days);
    return days;
  };

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date);
    if (fieldName === 'endDate') {
      getDaysUsingDates(formik.values.startDate, date);
    }
  };

  useEffect(() => {
    if (executionFlag) {
      const proofEligibilityCheck = checkProofEligibility(
        formik.values,
        catProofApplMap,
        catMinDaysProofThresholdMap,
        catProofThresholdApplMap,
        'onEndDateSelect',
        message,
        transactionPermitted,
      );
      if (proofEligibilityCheck.transactionPermitted === false) transactionPermitted = false;
      if (proofEligibilityCheck.attachmentRequiredAsterisk === true) {
        setAttachmentRequiredAsterisk(true);
      } else {
        setAttachmentRequiredAsterisk(false);
      }
      setMessage(proofEligibilityCheck.errorMsg);
    }
    setExecutionFlag(true);
  }, [formik.values.numberOfDays]);

  useEffect(() => {
    getDaysUsingDates(formik.values.startDate, formik.values.endDate);
    if (
      formik.values.leaveType === 'Half Day Leave - 1st Half' ||
      formik.values.leaveType === 'Half Day Leave - 2nd Half'
    ) {
      setShowFields({ ...showFields, endDate: false, numberOfHours: false });
      formik.setFieldValue('numberOfDays', 0.5);
    } else if (formik.values.leaveType === 'Partial Day Leave') {
      setShowFields({ ...showFields, endDate: false, numberOfHours: true });
    } else {
      setShowFields({ ...showFields, endDate: true, numberOfHours: false });
      if (formik.values.endDate)
        formik.setFieldValue('numberOfDays', getDaysUsingDates(formik.values.startDate, formik.values.endDate));
    }
  }, [formik.values.leaveType, formik.values.numberOfHours]);

  const handleLeaveTypeChange = (e) => {
    formik.handleChange(e);
    getDaysUsingDates(formik.values.startDate, formik.values.endDate);
  };

  const handleLeaveCategoryChange = async (e) => {
    const newCategory = e.target.value;
    formik.handleChange(e);
    const proofEligibilityCheck = checkProofEligibility(
      { ...formik.values, leaveCategory: newCategory },
      catProofApplMap,
      catMinDaysProofThresholdMap,
      catProofThresholdApplMap,
      'onCategoryChange',
      message,
      transactionPermitted,
    );
    if (proofEligibilityCheck.transactionPermitted === false) transactionPermitted = false;
    if (proofEligibilityCheck.attachmentRequiredAsterisk === true) {
      setAttachmentRequiredAsterisk(true);
    } else {
      setAttachmentRequiredAsterisk(false);
    }
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <div dangerouslySetInnerHTML={{ __html: workingDayMessage }} />

      <Typographys variant="h6">Leave Details</Typographys>
      <Grid container rowSpacing={2} columnSpacing={2} mt={0} sx={leaveStyle.formContainer}>
        <Grid item xs={12} md={4}>
          <Dropdown
            error={Boolean(formik.touched.leaveCategory && formik.errors.leaveCategory)}
            helperText={formik.touched.leaveCategory && formik.errors.leaveCategory}
            handleChange={async (e) => handleLeaveCategoryChange(e)}
            onBlur={formik.handleBlur}
            value={formik.values.leaveCategory}
            name="leaveCategory"
            id="leaveCategory"
            label="Leave Category*"
            options={leaveCategoryOptions}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Dropdown
            error={Boolean(formik.touched.leaveType && formik.errors.leaveType)}
            helperText={formik.touched.leaveType && formik.errors.leaveType}
            handleChange={(e) => handleLeaveTypeChange(e)}
            onBlur={formik.handleBlur}
            value={formik.values.leaveType}
            name="leaveType"
            id="leaveType"
            label="Leave Type*"
            options={leaveTypeOptions}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            error={Boolean(formik.touched.startDate && formik.errors.startDate)}
            helperText={formik.touched.startDate && formik.errors.startDate}
            onChange={(date) => handleDateChange(date, 'startDate')}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
            minDate={leaveFormData?.dateOfJoining}
            name="startDate"
            id="startDate"
            label="Start Date*"
            fullWidth
          />
        </Grid>

        {showFields.endDate && (
          <Grid item xs={12} md={4}>
            <DatePicker
              error={Boolean(formik.touched.endDate && formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
              onChange={(date) => handleDateChange(date, 'endDate')}
              onBlur={formik.handleBlur}
              value={formik.values.endDate}
              minDate={formik.values.startDate}
              name="endDate"
              id="endDate"
              label="End Date*"
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <InputField
            error={Boolean(formik.touched.numberOfDays && formik.errors.numberOfDays)}
            helperText={formik.touched.numberOfDays && formik.errors.numberOfDays}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={formik.handleBlur}
            value={formik.values.numberOfDays}
            name="numberOfDays"
            id="numberOfDays"
            label="Number of Days"
            type="number"
            disabled
            fullWidth
          />
        </Grid>

        {showFields.numberOfHours && (
          <Grid item xs={12} md={4}>
            <InputField
              InputLabelProps={{
                shrink: true,
              }}
              placeholder=""
              error={Boolean(formik.touched.numberOfHours && formik.errors.numberOfHours)}
              helperText={formik.touched.numberOfHours && formik.errors.numberOfHours}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.numberOfHours}
              name="numberOfHours"
              id="numberOfHours"
              inputProps={{ min: 0, max: standardWorkingHoursPerDay }}
              label="Number of Hours"
              type="number"
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <InputField
            error={Boolean(formik.touched.description && formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            name="description"
            id="description"
            label="Description*"
            fullWidth
            multiline
          />
        </Grid>
        {attachmentRequired && (
          <Grid item xs={12} md={12}>
            <Typographys variant="h6" sx={{ mb: 1.5 }}>
              Attach Proof{attachmentRequiredAsterisk && <span>*</span>}
            </Typographys>
            <Attachments
              isDeleteIcon
              handleChange={(event) => {
                formik.setFieldValue(`attachments`, event.target.files[0]);
              }}
              name="attachments"
              id="attachments"
              value={formik.values.attachments}
              handleDelete={() => {
                formik.setFieldValue(`attachments`, '');
              }}
            />
            <Grid item xs={12} md={4} mt={2}>
              {!!isEdit && (
                <Typographys variant="caption" color="neutral.600">
                  Previously Associated Attachment: {formik.values?.attachment || 'No Files Attached.'}
                </Typographys>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box sx={leaveStyle.btnContainer}>
        <Box sx={leaveStyle.submitWrapper}>
          {isEdit && (
            <Button
              color="error"
              variant="outlined"
              startIcon={<DeleteIcon fontSize="2.5rem" />}
              size="large"
              type="submit"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
          <Box sx={leaveStyle.submitButtons}>
            <Button
              size="large"
              color="info"
              variant="outlined"
              type="cancel"
              onClick={() => {
                formik.resetForm();
                handleCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              type="button"
              variant="outlined"
              onClick={async () => {
                await formik.setTouched(
                  Object.keys(formik.values).reduce((acc, key) => {
                    acc[key] = true;
                    return acc;
                  }, {}),
                );
                const errors = await formik.validateForm();
                if (Object.keys(errors).length === 0 && canSubmitDetails(formik.values, 'buttonClick')) {
                  handleDraft({ ...formik.values, isSandwichRuleApplicable });
                }
              }}
              loading={loading}
            >
              Save To Draft
            </LoadingButton>
            <LoadingButton color="primary" variant="contained" size="large" type="submit" loading={loading}>
              {isEdit ? 'Update' : 'Submit'}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

LeaveRequestForm.defaultProps = {
  leaveFormData: {},
  handleSubmit: () => {},
  leaveCategoryOptions: [],
  isEdit: false,
  handleDraft: () => {},
  handleDelete: () => {},
  handleCancel: () => {},
};

LeaveRequestForm.propTypes = {
  leaveFormData: PropTypes.shape({ dateOfJoining: PropTypes?.string }),
  handleSubmit: PropTypes.func,
  leaveCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  isEdit: PropTypes.bool,
  handleDraft: PropTypes.func,
  handleDelete: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default LeaveRequestForm;
