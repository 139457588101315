export const checkProofEligibility = (
  values,
  catProofApplMap,
  catMinDaysProofThresholdMap,
  catProofThresholdApplMap,
  triggerPoint,
  errorMsg,
  transactionPermitted,
) => {
  const catID = values.leaveCategory;
  let dayCount = 0;
  const numDaysElem = values.numberOfDays;
  if (numDaysElem) dayCount = numDaysElem;

  let categoryProofAppl = false;
  let minDaysForForProofThreshold = 0.0;
  let fileAttached = false;
  let isProofThresholdRequired = false;
  let attachmentRequiredAsterisk = false;

  if (catID && catProofApplMap[catID]) categoryProofAppl = catProofApplMap[catID];
  if (catID && catMinDaysProofThresholdMap[catID]) minDaysForForProofThreshold = catMinDaysProofThresholdMap[catID];
  if (catID && catProofThresholdApplMap[catID]) isProofThresholdRequired = catProofThresholdApplMap[catID];
  // let errorMsg = '';
  const attachElem = values?.attachments;
  if (attachElem) fileAttached = true;
  if (categoryProofAppl) {
    if (triggerPoint === 'onCategoryChange' || triggerPoint === 'buttonClick') attachmentRequiredAsterisk = true;
    if (isProofThresholdRequired) {
      if (minDaysForForProofThreshold > 0) {
        if (dayCount > minDaysForForProofThreshold) {
          if (!fileAttached) {
            if (triggerPoint === 'onEndDateSelect' || triggerPoint === 'onCategoryChange')
              attachmentRequiredAsterisk = true;
            transactionPermitted = false;
            if (triggerPoint === 'buttonClick') {
              errorMsg += `<i style='color: RED'>ERROR: Chosen Leave Category requires Proof to be Submitted.
             Please submit proof and retry. </i><br/>`;
            }
          }
        }
        if (dayCount <= minDaysForForProofThreshold) {
          if (triggerPoint === 'onEndDateSelect' || triggerPoint === 'onCategoryChange')
            attachmentRequiredAsterisk = false;
        }
      } else if (!fileAttached) {
        if (triggerPoint === 'onEndDateSelect' || triggerPoint === 'onCategoryChange')
          attachmentRequiredAsterisk = true;
        transactionPermitted = false;
        if (triggerPoint === 'buttonClick') {
          errorMsg += `<i style='color: RED'>ERROR: Chosen Leave Category requires Proof to be Submitted.
           Please submit proof and retry. </i><br/>`;
        }
      }
    } else if (!fileAttached) {
      if (triggerPoint === 'onEndDateSelect' || triggerPoint === 'onCategoryChange') attachmentRequiredAsterisk = true;
      transactionPermitted = false;
      if (triggerPoint === 'buttonClick') {
        errorMsg += `<i style='color: RED'>ERROR: Chosen Leave Category requires Proof to be Submitted.
         Please submit proof and retry. </i><br/>`;
      }
    }
  }

  return { errorMsg, transactionPermitted, attachmentRequiredAsterisk };
};
