/* eslint-disable no-unsafe-optional-chaining */
import dayjs from 'dayjs';
import { validateDateElseHyphen } from 'utils/commonUtils';
import { validateNumberElseHyphen } from '../../../../utils/commonUtils';

const getConfiguredTDSWorkingDetails = (TDSWorkingInstance) => {
  if (!TDSWorkingInstance?.data) return null;
  const taxWorkingInstance = TDSWorkingInstance?.data?.taxWorkingInstance;
  const companyConfig = TDSWorkingInstance?.data?.companyConfig;
  const currencySymbol = taxWorkingInstance?.currency?.symbol || '';

  const columns = TDSWorkingInstance?.data?.payPeriodsMap
    ? Object.entries(TDSWorkingInstance?.data?.payPeriodsMap).map(([key, value]) => {
        return {
          fieldName: dayjs(value?.payPeriodStartDate).format('MMMM').toLowerCase(),
          label: dayjs(value?.payPeriodStartDate).format('MMMM YYYY'),
          formatValue: ({ row, column }) => validateNumberElseHyphen(row[column.fieldName]),
        };
      })
    : [];
  const TDSWorkingDetails = {
    empName: TDSWorkingInstance?.data?.employee?.fullName || '-',
    exemptionCalculationBasis: taxWorkingInstance?.exemptionCalculationBasis || '',
    empId: TDSWorkingInstance?.data?.employee?.profile?.employeeID || '-',
    categoryColumnsData: [
      ...columns,
      {
        fieldName: 'total',
        label: 'Sub Total',
        formatValue: ({ row, column }) => validateNumberElseHyphen(row[column.fieldName]),
      },
    ],
    categoryRowsData: taxWorkingInstance?.categoryRowsData || [],
    amountData: taxWorkingInstance?.amountData || {},
    hraAmount: `${currencySymbol} ${taxWorkingInstance?.totalHRARecieved}`,
    totalRentPaid: `${currencySymbol} ${taxWorkingInstance?.annualRentPaid}`,
    standardDeduction: `${currencySymbol} ${taxWorkingInstance?.standardDeduction}`,
    currentFinancialYear: companyConfig?.currentFinancialYear,
    taxOnEmployment: taxWorkingInstance?.totalAnnualDeductionsUnderSection16,
    deductionTableData: taxWorkingInstance?.finalDeductionTableData || [],
    totalDeductionAmount: taxWorkingInstance?.totalAnnualDeductionsUnderChapterVIA,
    rowEarnings: taxWorkingInstance?.rowEarnings || [],
    columnEarnings: [
      {
        fieldName: 'earnings',
        columnTitle: 'Earnings',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    rowDeductions: taxWorkingInstance?.rowDeductions || [],
    columnDeductions: [
      {
        fieldName: 'deductions',
        columnTitle: 'Deductions',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    rowRetirals: taxWorkingInstance?.rowRetirals || [],
    columnRetirals: [
      {
        fieldName: 'retirals',
        columnTitle: 'Retirals',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    rowOther: taxWorkingInstance?.rowOther || [],
    columnOther: [
      {
        fieldName: 'others',
        columnTitle: 'Others',
        columnWidth: '60%',
      },
      {
        fieldName: 'perPeriodAmount',
        columnTitle: 'Per Period',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualAmount',
        columnTitle: 'Annual',
        columnWidth: '20%',
      },
    ],
    hraData: taxWorkingInstance?.hraData || [],
    rowExemption: taxWorkingInstance?.finalExemptionTableData || [],
    columnExemption: [
      {
        fieldName: 'title',
        columnTitle: 'Title',
        columnWidth: '20%',
      },
      {
        fieldName: 'declaredamount',
        columnTitle: 'Declared Amount',
        columnWidth: '20%',
      },
      {
        fieldName: 'proofsubmitted',
        columnTitle: 'Proof Submitted',
        columnWidth: '20%',
      },
      {
        fieldName: 'annualexemptionlimit',
        columnTitle: 'Annual Exemption Limit',
        columnWidth: '20%',
      },
      {
        fieldName: 'exemptionconsidered',
        columnTitle: 'Exemption Considered',
        columnWidth: '20%',
      },
    ],
    pastEmployerTaxDetails: taxWorkingInstance?.pastEmployerTaxDetails,
    startDate: validateDateElseHyphen(taxWorkingInstance?.company?.financialYearStartDate, 'DD MMMM YYYY'),
    endDate: validateDateElseHyphen(taxWorkingInstance?.company?.financialYearEndDate, 'DD MMMM YYYY'),
    currencySymbol,
  };

  return TDSWorkingDetails;
};

export { getConfiguredTDSWorkingDetails };
