import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import useFetcher from 'hooks/useFetcher';
import CompareTDSTemplateTemplate from 'components/Templates/MyPay/CompareTDS';
import dayjs from 'dayjs';
import { compareTDSDetails } from 'api/home/myPay';
import { getConfiguredTDSWorkingDetails } from './getConfiguredCompareTDS';
import getColumns from './getColumns';

const CompareTDSPage = () => {
  const [compareTDSInstance] = useFetcher(compareTDSDetails);
  const compareTDSApiData = getConfiguredTDSWorkingDetails(compareTDSInstance?.data);

  const [categoryColumnsData] = getColumns(compareTDSApiData?.sortedPayPeriods);

  if (compareTDSInstance?.isLoading) {
    return (
      <Box sx={{ p: 4 }}>
        <Skeleton height={70} />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  }

  if (compareTDSInstance?.error !== null) {
    return (
      <Typography variant="h6">
        {compareTDSInstance?.error || 'Error while fetching compare TDS information.'}
      </Typography>
    );
  }

  return (
    <CompareTDSTemplateTemplate
      empName={compareTDSApiData?.empName}
      empId={compareTDSApiData?.employeeID}
      currencySymbol={compareTDSApiData?.currencySymbol}
      categoryColumnsData={categoryColumnsData}
      categoryRowsData={compareTDSApiData?.categoryRowsData}
      amountData={compareTDSApiData?.amountData}
      hraAmount={compareTDSApiData?.hraAmount}
      totalRentPaid={compareTDSApiData?.totalRentPaid}
      taxOnEmployment={compareTDSApiData?.totalAnnualDeductionsUnderSection16}
      deductionTableData={compareTDSApiData?.deductionTableData}
      totalDeductionAmount={compareTDSApiData?.totalAnnualDeductionsUnderChapterVIA}
      hraData={compareTDSApiData?.hraData}
      incomeData={compareTDSApiData?.incomeData}
      employerNames={compareTDSApiData?.employerNames}
      statutoryData={compareTDSApiData?.statutoryData}
      tdsWorkingProfile={compareTDSApiData?.tdsWorkingProfile}
      totalExemptionPrice={compareTDSApiData?.totalAnnualExemptionsUnderSection10}
      startDate={compareTDSApiData?.startDate ? dayjs(compareTDSApiData.startDate).format('DD-MMM-YYYY') : ''}
      endDate={compareTDSApiData?.endDate ? dayjs(compareTDSApiData.endDate).format('DD-MMM-YYYY') : ''}
      basicPerc={compareTDSApiData?.basicPerc}
      standardDeduction={compareTDSApiData?.standardDeduction}
    />
  );
};

export default CompareTDSPage;
