const getConfiguredTDSWorkingDetails = (compareTDSInstance) => {
  const compareTDSDetails = {
    empName: compareTDSInstance?.empName,
    employeeID: compareTDSInstance?.employeeID,
    currencySymbol: compareTDSInstance?.currencySymbol,
    categoryRowsData: compareTDSInstance?.categoryRowsData,
    amountData: compareTDSInstance?.amountData,
    hraAmount: compareTDSInstance?.hraAmount,
    totalRentPaid: compareTDSInstance?.totalRentPaid,
    taxOnEmployment: compareTDSInstance?.taxOnEmployment,
    deductionTableData: compareTDSInstance?.deductionTableData,
    totalDeductionAmount: compareTDSInstance?.totalDeductionAmount,
    hraData: compareTDSInstance?.hraData,
    incomeData: compareTDSInstance?.incomeData,
    employerNames: compareTDSInstance?.employerNames,
    statutoryData: compareTDSInstance?.statutoryData,
    tdsWorkingProfile: compareTDSInstance?.tdsWorkingProfile,
    totalExemptionPrice: compareTDSInstance?.totalExemptionPrice,
    startDate: compareTDSInstance?.startDate,
    endDate: compareTDSInstance?.endDate,
    basicPerc: compareTDSInstance?.basicPerc,
    standardDeduction: compareTDSInstance?.standardDeduction,
    sortedPayPeriods: compareTDSInstance?.sortedPayPeriods,
  };

  return compareTDSDetails;
};

export { getConfiguredTDSWorkingDetails };
