import React from 'react';
import TDSWorkingTemplateTemplate from 'components/Templates/MyPay/TDSWorking';
import useFetcher from 'hooks/useFetcher';
import { Typography, Skeleton, Box } from '@mui/material';
import { getTDSWorkingDetails, getMySalaryDetails } from 'api/home/myPay';
import { getConfiguredTDSWorkingDetails } from './getConfiguredTDSWorkings';

const TdsWorkings = () => {
  const [TDSWorkingDetails] = useFetcher(getTDSWorkingDetails);
  const configuredTDSData = getConfiguredTDSWorkingDetails(TDSWorkingDetails);

  if (TDSWorkingDetails.error !== null) {
    return (
      <Typography variant="h6">
        Salary and TDS details are not available. Please contact your Administrator for further assistance.
      </Typography>
    );
  }

  return TDSWorkingDetails.isLoading ? (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton />
      <Skeleton />
    </Box>
  ) : (
    <TDSWorkingTemplateTemplate
      empName={configuredTDSData.empName}
      empId={configuredTDSData.empId}
      categoryColumnsData={configuredTDSData.categoryColumnsData}
      categoryRowsData={configuredTDSData.categoryRowsData}
      amountData={configuredTDSData.amountData}
      hraAmount={configuredTDSData.hraAmount}
      totalRentPaid={configuredTDSData.totalRentPaid}
      standardDeduction={configuredTDSData.standardDeduction}
      currentFinancialYear={configuredTDSData.currentFinancialYear}
      taxOnEmployment={configuredTDSData.taxOnEmployment}
      deductionTableData={configuredTDSData.deductionTableData}
      totalDeductionAmount={configuredTDSData.totalDeductionAmount}
      rowEarnings={configuredTDSData.rowEarnings}
      rowDeductions={configuredTDSData.rowDeductions}
      rowOther={configuredTDSData.rowOther}
      rowRetirals={configuredTDSData.rowRetirals}
      hraData={configuredTDSData.hraData}
      rowExemption={configuredTDSData.rowExemption}
      startDate={configuredTDSData.startDate}
      endDate={configuredTDSData.endDate}
      loading={TDSWorkingDetails?.isLoading}
      currencySymbol={configuredTDSData?.currencySymbol || ''}
      exemptionCalculationBasis={configuredTDSData?.exemptionCalculationBasis}
      pastEmployerTaxDetails={configuredTDSData?.pastEmployerTaxDetails}
    />
  );
};

export default TdsWorkings;
