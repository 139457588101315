import React from 'react';
import { useSelector } from 'react-redux';
import useFetcher from 'hooks/useFetcher';
import useToast from 'components/Provider/useToast';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getAlumniDashboardDetails } from 'api/home';
import dayjs from 'dayjs';
import useDownloadFile from 'hooks/useDownloadFile';
import { hasPermission } from 'utils/permissions';
import { isArray, isEmpty } from 'lodash';
import { validateElseHyphen, validateNumberElseHyphen } from 'utils/commonUtils';
import AlumniDashboardTemplate from 'components/Templates/Dashboard/AlumniDashboard';
import useColumns from '../MyPay/SalarySlipList/useColumns';
import { getColumns } from '../MyPay/MySalaryDetails/getColumns';
import { getConfiguredSalaryDetails } from '../MyPay/MySalaryDetails/getConfiguredSalaryDetails';
import { getConfiguredFnfDetails } from './getConfiguredFnfDetails';
import { getConfiguredTDSWorkingDetails } from '../MyPay/TDSWorkings/getConfiguredTDSWorkings';
import { getConfiguredDDocumentDetails } from './getConfiguredDocumentDetails';

const AlumniDashboardPage = () => {
  const addToast = useToast();
  const downloadFile = useDownloadFile();
  const navigate = useNavigate();
  const [salarySlipDataColumn] = useColumns();
  const [alumniDetails] = useFetcher(getAlumniDashboardDetails);

  const authorities = useSelector((state) => state.session.user.authorities);
  const { salarySlipList } = useSelector((state) => state.salarySlip);
  const salarySlipData = alumniDetails?.data?.salarySlips?.salarySlipInstanceList;
  const consolidatedSalarySlipFlag = alumniDetails?.data?.salarySlips?.consolidatedSalarySlips;

  const myPayMySalaryDetails = alumniDetails?.data?.mySalaryDetails;

  const configuredSalaryDetails = getConfiguredSalaryDetails(myPayMySalaryDetails);
  const salaryDetailsColumns = getColumns();

  const TDSWorkingDetails = { data: alumniDetails?.data?.tdsWorkingDetails };
  const configuredTDSData = getConfiguredTDSWorkingDetails(TDSWorkingDetails, myPayMySalaryDetails);

  const fnfDetails = alumniDetails?.data?.fnfSalarySlipInstance;
  const configuredFnfDetails = getConfiguredFnfDetails(fnfDetails);

  const employeeDocumentDetails = alumniDetails?.data?.docDetails?.employeeDocuments;
  const configuredDocumentDetails = getConfiguredDDocumentDetails(employeeDocumentDetails, downloadFile);

  const userHasViewFnFStatementPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_FNFS_VIEW_FINAL_SALARY_SLIP',
  ]);
  const userHasPrintSalarySlipPermission = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_PRINT_MY_SALARY_SLIP',
  ]);
  const userHasViewSalarySlipDetails = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_PAY_INDEX_VIEW_EMPLOYEE_SALARY_SLIP_DETAILS',
  ]);
  const userHasViewDocumentListPermission = hasPermission(authorities, ['ROLE_BASE_PERMISSION_INDEX_LIST_DOCUMENTS']);

  if (alumniDetails?.error !== null) {
    return (
      <Typography variant="h6">{alumniDetails?.error?.message || 'Error while fetching salary slips.'}</Typography>
    );
  }

  const fetchSalarySlipDetailsFunc = (row) => {
    if (row?.ssid) {
      navigate(`/home/mypay/salary-slips-details/${row.ssid}`, {
        state: { consolidatedSalarySlipsFlag: row?.consolidatedSalarySlipsFlag, salarySlipId: row?.salarySlipId },
      });
    } else {
      addToast({
        type: 'error',
        title: 'ERROR',
        message: 'Something went wrong, try again',
      });
    }
  };

  if (alumniDetails.error !== null) {
    return (
      <Typography variant="h6">
        {alumniDetails?.error?.response?.data?.message || 'Error while fetching alumni dashboard details.'}
      </Typography>
    );
  }

  const onSalarySlipRowRowClick = (row) => {
    fetchSalarySlipDetailsFunc(row);
  };

  const checkAndUpdateValue = (value1, value2, dateFormat) => {
    if (value1) {
      return dayjs(value1).format(dateFormat);
    }
    if (value2) {
      return dayjs(value1).format(dateFormat);
    }
    return '-';
  };

  const salarySlipDataRow =
    isArray(salarySlipData) && !isEmpty(salarySlipData)
      ? salarySlipData.map((val, index) => ({
          ssid: validateElseHyphen(val?.id || val?.payPeriod?.id),
          slNo: index + 1,
          periodStartDate: checkAndUpdateValue(val?.periodStartDate, val?.payPeriod?.payPeriodStartDate, 'DD MMM YYYY'),
          periodEndDate: checkAndUpdateValue(val?.periodEndDate, val?.payPeriod?.payPeriodEndDate, 'DD MMM YYYY'),
          payPeriod: `${checkAndUpdateValue(
            val?.periodStartDate,
            val?.payPeriod?.payPeriodStartDate,
            'DD MMM YYYY',
          )} - ${checkAndUpdateValue(val?.periodEndDate, val?.payPeriod?.payPeriodEndDate, 'DD MMM YYYY')}`,
          grossEarnings: `₹ ${validateNumberElseHyphen(val?.netFinalEarnings)}`,
          deductions: `₹ ${validateNumberElseHyphen(val?.netFinalDeductions)}`,
          netEarnings: `₹ ${validateNumberElseHyphen(val?.netFinalSalary)}`,
          consolidatedSalarySlipsFlag: !!consolidatedSalarySlipFlag,
          salarySlipId: val?.salarySlipId,
        }))
      : [];

  return (
    <AlumniDashboardTemplate
      Heading={configuredFnfDetails?.employeeFirstName}
      configuredFnfDetails={configuredFnfDetails || {}}
      configuredTDSData={configuredTDSData || {}}
      configuredSalaryDetails={configuredSalaryDetails || {}}
      salaryDetailsColumns={salaryDetailsColumns}
      salarySlipDataColumn={salarySlipDataColumn}
      salarySlipDataRow={salarySlipDataRow}
      onSalarySlipRowRowClick={userHasViewSalarySlipDetails ? onSalarySlipRowRowClick : () => {}}
      configuredDocumentDetails={configuredDocumentDetails || {}}
      loading={alumniDetails?.isLoading}
      userHasViewFnFStatementPermission={userHasViewFnFStatementPermission}
      userHasPrintSalarySlipPermission={userHasPrintSalarySlipPermission}
      userHasViewDocumentListPermission={userHasViewDocumentListPermission}
    />
  );
};

export default AlumniDashboardPage;
