import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import Button from 'components/Atoms/Button';
import { Print } from '@material-ui/icons';
import { CompareTDSStyle } from 'components/Organisms/MyPay/CompareTDS/style';
import CategoryTable from 'components/Organisms/MyPay/CompareTDS/categoryTable';
import DeductionTable from 'components/Organisms/MyPay/CompareTDS/deductionTable';
import HRATable from 'components/Organisms/MyPay/CompareTDS/hraTable';
import HeadsTable from 'components/Organisms/MyPay/CompareTDS/headsTable';
import IncomeTable from './incomeTable';
import { validateDateElseHyphen } from '../../../../utils/commonUtils';

const CompareTDS = (
  {
    empName,
    empId,
    currencySymbol,
    categoryColumnsData,
    categoryRowsData,
    amountData,
    hraAmount,
    totalRentPaid,
    taxOnEmployment,
    deductionTableData,
    totalDeductionAmount,
    hraData,
    incomeData,
    employerNames,
    statutoryData,
    totalExemptionPrice,
    startDate,
    endDate,
    basicPerc,
    handlePrint,
    standardDeduction,
  },
  ref,
) => {
  const headsData = [
    {
      title: 'A. Total Taxable Earnings (From Salary)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.a?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.a?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'B. Income from Previous Employer',
      oldRegimeAmount: `${currencySymbol} ${amountData?.b?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.b?.new?.toLocaleString() || 0}`,
    },
  ].filter((headData) => headData.newRegimeAmount !== '' && headData.oldRegimeAmount !== '');

  const headsData1 = [
    {
      title: 'C. Taxable Perquisites Value',
      oldRegimeAmount: `${currencySymbol} ${amountData?.c?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.c?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'D. Total Earnings (A + C)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.d?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.d?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'E. Less Exemptions Under Section 10',
      oldRegimeAmount: `${currencySymbol} ${amountData?.e?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.e?.new?.toLocaleString() || 0}`,
    },
  ].filter((headData) => headData.newRegimeAmount !== '' && headData.oldRegimeAmount !== '');

  const balanceData = [
    {
      title: 'F. Balance (D - E)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.f?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.f?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'G. Less Deductions Under Section 16',
      oldRegimeAmount: `${currencySymbol} ${amountData?.g?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.g?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'H. Income Chargeable Under Head Salaries (F - G)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.h?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.h?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'I. Add any other income declared by Employee',
      oldRegimeAmount: `${currencySymbol} ${amountData?.i?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.i?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'J. Less any other deductions claimed by Employee',
      oldRegimeAmount: `${currencySymbol} ${amountData?.j?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.j?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'K. Gross Total Income (H + I - J)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.k?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.k?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'L. Less Deductions Under Chapter VI A',
      oldRegimeAmount: `${currencySymbol} ${amountData?.l?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.l?.new?.toLocaleString() || 0}`,
    },
  ].filter((headData) => headData.newRegimeAmount !== '' && headData.oldRegimeAmount !== '');

  const eligibleDeductionData = [
    {
      title: 'M. Total Eligible Deduction',
      subTitle: '(Under Section 80)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.m?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.m?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'N. Taxable Income (K + B - M) ',
      subTitle: '(Rounded to nearest 10s per Section 288A)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.n?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.n?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'O. Tax on Income (N)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.o?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.o?.new?.toLocaleString() || 0}`,
    },
    {
      title: `P. Less Rebate US 87A, before Surcharge and Cess `,
      subTitle: '(applicable only if Taxable income under N is less than 5,00,000/-)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.p?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.p?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'Q. Surcharge on Income Tax (O)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.q?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.q?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'R. Total Tax Payable (incl of Surcharge) (O - P + Q)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.r?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.r?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'S. Education & Health Cess (@4% of R)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.s?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.s?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'T. Total Tax Liability (R + S) ',
      subTitle: '(rounded to nearest 10s per Section 288B)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.t?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.t?.new?.toLocaleString() || 0}`,
    },
    {
      title: 'U. Net Tax Collected YTD ',
      subTitle: '(inclusive of Past Employment Taxes from B)',
      oldRegimeAmount: `${currencySymbol} ${amountData?.u?.old?.toLocaleString() || 0}`,
      newRegimeAmount: `${currencySymbol} ${amountData?.u?.new?.toLocaleString() || 0}`,
    },
  ].filter((headData) => headData.newRegimeAmount !== '' && headData.oldRegimeAmount !== '');

  if (hraAmount !== 0) {
    headsData1[3] = {
      title: `House Rent Allowance : Section 10(13A) : ${currencySymbol}${hraAmount?.toLocaleString() || 0}`,
      type: 'subtitle',
    };
  }

  if (!totalRentPaid?.includes('undefined')) {
    headsData1[4] = {
      title: `Total Rent Paid p.a. : ${currencySymbol}${totalRentPaid?.toLocaleString() || 0}`,
      type: 'subtitle',
    };
  }

  if (!taxOnEmployment?.includes('undefined')) {
    balanceData.splice(3, 1, {
      title: `2. Tax on Employment : Section 16(iii) : ${currencySymbol}${
        totalDeductionAmount?.toLocaleString() || 0
      } `,
      type: 'subtitle',
    });
  }

  if (balanceData.length) {
    const standardDeductionOption = {
      title: `1. Standard Deduction : Section 16(i) : ${currencySymbol}${taxOnEmployment?.toLocaleString() || 0}`,
      type: 'subtitle',
    };

    if (balanceData.length > 2) {
      balanceData.splice(2, 1, standardDeductionOption);
    } else {
      balanceData.push(standardDeductionOption);
    }
  }

  const theme = useTheme();
  return (
    <Box className="compareTds" ref={ref}>
      <Box bgcolor="info.200" padding="2rem" borderRadius="0.75rem" sx={{ mb: 2 }}>
        <Typographys color="neutral.800" fontWeight="500" variant="body2">
          Note
        </Typographys>
        <Typographys color="neutral.600" variant="body2">
          Tax projections for upcoming months may fluctuate during payroll processing or other related activities. This
          will stabilize once payroll is finalized for every month. If any discrepancies found, please check again later
          or raise support query if discrepancies persists. <br />
        </Typographys>
      </Box>
      <Stack sx={CompareTDSStyle.sectionHeadingWithBtn}>
        <Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem' }}>
            <Typographys variant="body1" color="neutral.600" display="inline">
              TDS Working Statement for
            </Typographys>
            <Typographys variant="h5" color="neutral.800" display="inline">
              {empName} ({empId})
            </Typographys>
          </Box>
          <Typographys variant="body2" color="neutral.600">
            Period: {validateDateElseHyphen(startDate, 'MMMM DD, YYYY')} -{' '}
            {validateDateElseHyphen(endDate, 'MMMM DD, YYYY')}
          </Typographys>
        </Box>

        <Box className="no-print">
          <Button
            sx={CompareTDSStyle.printButton}
            color="secondary"
            startIcon={<Print sx={{ fontSize: '2.5rem' }} />}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Box>
      </Stack>
      <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
      <CategoryTable categoryColumnsData={categoryColumnsData} categoryRowsData={categoryRowsData} />
      <Divider sx={{ my: 3, borderColor: theme.palette.other[200] }} />
      <Typographys variant="body2" color="neutral.800">
        * Indicates Non-Taxable Earnings
      </Typographys>
      <Box mt={2.5}>
        {!!headsData.length && !!headsData1.length && !!eligibleDeductionData.length && !!balanceData.length && (
          <HeadsTable headsData={headsData} hasHeader />
        )}
        {!!incomeData?.length && (
          <Box m="0rem 0 1rem">
            <IncomeTable incomeData={incomeData} employerNames={employerNames} />
          </Box>
        )}
        {!!headsData1.length && <HeadsTable headsData={headsData1} hasHeader={false} />}
        <Box m="2.5rem 0 1rem">
          <HRATable hraData={hraData} basicPerc={basicPerc} />
        </Box>
        {statutoryData.map((data) => (
          <Box m="2rem 0 0rem">
            <Typographys variant="body2" color="neutral.800">
              {data.section}: ₹ {data.amount}
            </Typographys>
          </Box>
        ))}
        {!!standardDeduction && !!totalExemptionPrice && (
          <Box m="2rem 0 1rem">
            <Typographys variant="body2" color="neutral.800">
              Total Exemption {standardDeduction}: {totalExemptionPrice}
            </Typographys>
          </Box>
        )}
        {!!balanceData.length && <HeadsTable headsData={balanceData} />}
        {!!deductionTableData?.length && (
          <Box mt="2.5rem">
            <DeductionTable deductionTableData={deductionTableData} totalDeductionAmount={totalDeductionAmount} />
          </Box>
        )}
        {!!eligibleDeductionData.length && (
          <>
            <Divider sx={{ my: 2, borderColor: theme.palette.other[200] }} />
            <HeadsTable headsData={eligibleDeductionData} />
          </>
        )}
      </Box>
    </Box>
  );
};

CompareTDS.defaultProps = {
  empName: '',
  empId: '',
  categoryColumnsData: [],
  categoryRowsData: [],
  amountData: {},
  hraAmount: '',
  totalRentPaid: '',
  taxOnEmployment: '',
  deductionTableData: [],
  totalDeductionAmount: '',
  hraData: [],
  incomeData: [],
  statutoryData: [],
  totalExemptionPrice: '',
  startDate: '',
  endDate: '',
  basicPerc: '',
  handlePrint: () => {},
  standardDeduction: '',
  employerNames: [],
};

CompareTDS.propTypes = {
  statutoryData: PropTypes.arrayOf({}),
  totalExemptionPrice: PropTypes.string,
  empName: PropTypes.string,
  empId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  categoryColumnsData: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  categoryRowsData: PropTypes.shape({
    'Professional Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Income Tax': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Total Deductions': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
    'Net Earnings': PropTypes.shape({
      id: PropTypes.number,
      april: PropTypes.number,
      may: PropTypes.number,
      june: PropTypes.number,
      july: PropTypes.number,
      august: PropTypes.number,
      september: PropTypes.number,
      october: PropTypes.number,
      november: PropTypes.number,
      december: PropTypes.number,
      january: PropTypes.number,
      february: PropTypes.number,
      march: PropTypes.number,
      subTotal: PropTypes.number,
    }),
  }),
  amountData: PropTypes.shape(PropTypes.shape({})),
  hraAmount: PropTypes.string,
  totalRentPaid: PropTypes.string,
  taxOnEmployment: PropTypes.string,
  deductionTableData: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.string,
      investmentProposed: PropTypes.string,
      investmentActual: PropTypes.string,
      sectionLimit: PropTypes.string,
      deductionAmount: PropTypes.string,
    }),
  ),
  totalDeductionAmount: PropTypes.string,
  hraData: PropTypes.arrayOf(
    PropTypes.shape({
      received: PropTypes.string,
      basic: PropTypes.string,
      rentPaid: PropTypes.string,
    }),
  ),
  incomeData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        amount: PropTypes.string,
      }),
    ),
  ),
  basicPerc: PropTypes.string,
  handlePrint: PropTypes.func,
  standardDeduction: PropTypes.string,
  employerNames: PropTypes.arrayOf(PropTypes.string),
};

export default forwardRef(CompareTDS);
