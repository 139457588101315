import dayjs from 'dayjs';
import { isEmpty, isObject, isArray } from 'lodash';

const getHouseRentData = (tdsDetails) => {
  const { uniqueMnNumMonthNameMap = [], employeeDeductionDeclarationInstance = [] } = tdsDetails.data;
  const { mwLandlordNameMap, mwRentLandlordPanMap, mwLandlordAddressMap, mwMetroStatMap, mwRentDeclrMap } =
    employeeDeductionDeclarationInstance;

  const houseData =
    isObject(uniqueMnNumMonthNameMap) && !isEmpty(uniqueMnNumMonthNameMap)
      ? Object.keys(uniqueMnNumMonthNameMap).map((monthKey, index) => ({
          slNo: index + 1,
          id: monthKey,
          period: uniqueMnNumMonthNameMap?.[monthKey] || '',
          landlordName: mwLandlordNameMap?.[monthKey] || '',
          landlordPan: mwRentLandlordPanMap?.[monthKey] || '',
          landlordAddress: mwLandlordAddressMap?.[monthKey] || '',
          city: mwMetroStatMap?.[monthKey],
          rentPaid: mwRentDeclrMap?.[monthKey] || '',
        }))
      : [];

  return [...houseData];
};

const getInvestmentDeclarationData = (tdsDetails) => {
  const {
    deductionHeadsList = [],
    secRefDetailsMap = [],
    dedecHeadSubmittedProofsMap = [],
    dedecHeadDeclrAmtsMap = [],
    dedecHeadApprovedAmtsMap = [],
    dedecHeadLineEntryList = [],
  } = tdsDetails.data;
  const declarationData =
    isArray(deductionHeadsList) && !isEmpty(deductionHeadsList)
      ? deductionHeadsList.map((deductionHead) => {
          const dedecHeadSubmittedProofsMapId = dedecHeadLineEntryList.find(
            (item) => item?.deductionHeadId === deductionHead?.id,
          )?.id;

          const proofSubmittedAmount = dedecHeadLineEntryList.find(
            (item) => item?.deductionHeadId === deductionHead?.id,
          );

          const proof = dedecHeadSubmittedProofsMap[dedecHeadSubmittedProofsMapId]?.length
            ? dedecHeadSubmittedProofsMap[dedecHeadSubmittedProofsMapId][0]
            : {};

          return {
            id: deductionHead.id,
            title: `${deductionHead?.name} (${deductionHead?.statutoryReferenceSection})`,
            statutoryReferenceSection: deductionHead?.statutoryReferenceSection,
            tooltipTitle: deductionHead?.statutoryReferenceSection,
            tooltip: secRefDetailsMap && secRefDetailsMap[deductionHead.id],
            currDeclrAmt: dedecHeadDeclrAmtsMap?.[deductionHead?.id] || 0,
            currApprovedAmt: dedecHeadApprovedAmtsMap?.[deductionHead?.id] || 0,
            proof,
            proofId: proof?.id,
            proofSubmittedAmount,
          };
        })
      : [];

  return [...declarationData];
};

const configureUpdateFormData = async (bodyFormData, tdsDetails, declarationData) => {
  const uniqueMnNum = tdsDetails?.data?.employeeDeductionDeclarationInstance?.uniqueMonthNumberNamesMap || [];
  const houseRent =
    declarationData?.houseRent?.length &&
    declarationData?.houseRent?.forEach((payload) => {
      const rentIndex = declarationData?.houseRent.indexOf(payload);
      const key = Object.keys(uniqueMnNum)[rentIndex];
      bodyFormData.append(`landLordName_${key}`, payload?.landlordName);
      bodyFormData.append(`landLordAddress_${key}`, payload?.landlordAddress);
      bodyFormData.append(`metroCity_${key}`, payload?.city || '');
      bodyFormData.append(`landLordPan_${key}`, payload?.landlordPan);
      bodyFormData.append(`rentalAmount_${key}`, payload?.rentPaid);
    });
  const property =
    declarationData?.property?.length &&
    declarationData?.property?.forEach((payload) => {
      const index = declarationData?.property.indexOf(payload);
      bodyFormData.append(`housePropertyRecords[${index}].propertyType`, payload?.propertyType);
      bodyFormData.append(`housePropertyRecords[${index}].loanAmount`, payload?.loanAmount);
      bodyFormData.append(`housePropertyRecords[${index}].propertyName`, payload?.propertyName);
      bodyFormData.append(`housePropertyRecords[${index}].addressStr`, payload?.address);
      bodyFormData.append(
        `housePropertyRecords[${index}].loanBorrowedOn`,
        payload?.loanBorrowedOn ? dayjs(payload?.loanBorrowedOn).format('MM/DD/YYYY') : '',
      );
      bodyFormData.append(
        `housePropertyRecords[${index}].constructionCompletionDate`,
        payload?.constructionCompletedOn ? dayjs(payload?.constructionCompletedOn).format('MM/DD/YYYY') : '',
      );
      bodyFormData.append(`housePropertyRecords[${index}].lenderName`, payload?.lenderName);
      bodyFormData.append(`housePropertyRecords[${index}].lenderPan`, payload?.lenderPAN);
      bodyFormData.append(
        `housePropertyRecords[${index}].isJointlyOwnedProperty`,
        payload?.isJointlyOwnedProperty ? 'on' : '',
      );
      bodyFormData.append(
        `housePropertyRecords[${index}].isCompleteTaxExemAuthByAllBorrowers`,
        payload?.isCompleteTaxExemAuthByAllBorrowers ? 'on' : '',
      );
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower1Name`, payload?.coBorrower1Name);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower1Pan`, payload?.coBorrower1Pan);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower1Relationship`, payload?.coBorrower1Relationship);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower2Name`, payload?.coBorrower2Name);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower2Pan`, payload?.coBorrower2Pan);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower2Relationship`, payload?.coBorrower2Relationship);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower3Name`, payload?.coBorrower3Name);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower3Pan`, payload?.coBorrower3Pan);
      bodyFormData.append(`housePropertyRecords[${index}].coBorrower3Relationship`, payload?.coBorrower3Relationship);
      bodyFormData.append(`housePropertyRecords[${index}].year1PerEMIInterest`, payload?.emiInterest1Year);
      bodyFormData.append(`housePropertyRecords[${index}].year2PerEMIInterest`, payload?.emiInterest2Year);
      bodyFormData.append(
        `housePropertyRecords[${index}].employeeLoanPercent`,
        payload?.percentageOfInterestRepaid || '',
      );
      bodyFormData.append(
        `housePropertyRecords[${index}].housingLoanInterestOnSelfOccupiedProperty`,
        payload?.interest || '',
      );
      bodyFormData.append(`housePropertyRecords[${index}].lettableValue`, payload?.lettableValue);
      bodyFormData.append(
        `housePropertyRecords[${index}].taxPaidToLocalAuthorities`,
        payload?.taxPaidToLocalAuthorities,
      );
      bodyFormData.append(
        `housePropertyRecords[${index}].standardDeductionOnLettableValue`,
        payload?.standardDeductionOnLettableValue,
      );
      bodyFormData.append(
        `housePropertyRecords[${index}].interestPaidOnHousingLoanForLetOutProperty`,
        payload?.interestPaidOnHousingLoan,
      );
      bodyFormData.append(`housePropertyRecords[${index}].netIncomeFromHouseProperty`, payload?.netIncomeFromHouse);
    });
  const investmentDec =
    declarationData?.investmentDec?.length &&
    declarationData?.investmentDec?.forEach((payload) => {
      bodyFormData.append(`declaredAmount_${payload?.id}`, payload?.amount);
      if (payload?.statutoryReferenceSection === '10(13A)') {
        bodyFormData.append('dheadId1013A', payload?.id);
      }
      if (payload?.statutoryReferenceSection === '24') {
        bodyFormData.append('dheadId24', payload?.id);
      }
    });
  return { ...houseRent, ...property, ...investmentDec };
};
export { getHouseRentData, getInvestmentDeclarationData, configureUpdateFormData };
