import { validateDateElseHyphen, validateNumberElseHyphen } from 'utils/commonUtils';

const getDataArray = (array, type, currencySymbol, filterFunctions) => {
  const extractedArray =
    array.length > 0
      ? array.filter(filterFunctions || (() => true)).map((item) => ({
          [`${type}`]: [`) ${item?.payhead?.name} `.replace(/([A-Z])/g, ' $1').trim()],
          subLabel: item?.calculationFormula ? [`(${item?.calculationFormula})`] : '',
          perPeriodAmount: item?.finalPerPeriodValue
            ? `${currencySymbol} ${validateNumberElseHyphen(item?.finalPerPeriodValue)}`
            : '-',
          annualAmount: item?.finalAnnualValue
            ? `${currencySymbol} ${validateNumberElseHyphen(item?.finalAnnualValue) || ''}`
            : '-',
        }))
      : [];

  return extractedArray;
};

const filterFunctions = {
  earningsRows: (data) =>
    data?.finalAnnualValue && !data?.payhead?.isInternalPayhead && data?.payhead?.displayOnSalaryStructure,
  benefitsRows: (data) => data?.finalAnnualValue && data?.payhead?.displayOnSalaryStructure,
};

const getConfiguredSalaryDetails = (salaryDetails) => {
  const currencySymbol = salaryDetails?.defaultCurrency?.symbol;
  const statContributionsEmployerLineEntries = salaryDetails?.statContributionsEmployerLineEntries;
  const retiralFinalPerPeriodValue = (statContributionsEmployerLineEntries || []).reduce(
    (acc, curr) => (curr?.payhead?.displayOnSalaryStructure ? acc + (curr?.finalPerPeriodValue || 0) : acc),
    0,
  );
  const retiralFinalAnnualValue = (statContributionsEmployerLineEntries || []).reduce(
    (acc, curr) => (curr?.payhead?.displayOnSalaryStructure ? acc + (curr?.finalAnnualValue || 0) : acc),
    0,
  );

  const salaryDetailsProps = {
    employeeData: {
      joiningDate: validateDateElseHyphen(salaryDetails?.employeeInstance?.profile?.dateOfJoining, 'DD MMMM YYYY'),
      effectiveFrom: validateDateElseHyphen(salaryDetails?.salaryStructureInstance?.effectiveFrom, 'DD MMMM YYYY'),
      effectiveTo: validateDateElseHyphen(salaryDetails?.salaryStructureInstance?.effectiveTo, 'DD MMMM YYYY'),

      CTCBased: salaryDetails?.salaryStructureInstance?.isCTCBasedStructure.toString(),
      salaryStructureStatus: salaryDetails?.salaryStructureInstance?.status,
      payStructure: salaryDetails?.salaryStructureInstance?.basePayStructure?.displayName,
    },
    employeeId: salaryDetails?.employeeInstance?.profile?.employeeID,
    employeeName: salaryDetails?.employeeInstance?.fullName,
    earningsRows: salaryDetails?.earningsLineEntries
      ? getDataArray(salaryDetails?.earningsLineEntries, 'earnings', currencySymbol, filterFunctions.earningsRows)
      : [],
    benefitsRows: salaryDetails?.benefitsLineEntries
      ? getDataArray(salaryDetails?.benefitsLineEntries, 'benefits', currencySymbol, filterFunctions.benefitsRows)
      : [],
    reimbursementsRows: salaryDetails?.reimbursementsLineEntries
      ? getDataArray(
          salaryDetails?.reimbursementsLineEntries,
          'reimbursements',
          currencySymbol,
          filterFunctions.benefitsRows,
        )
      : [],
    variablePayRows: salaryDetails?.witholdingLineEntries
      ? getDataArray(salaryDetails?.witholdingLineEntries, 'variablePay', currencySymbol, filterFunctions.benefitsRows)
      : [],
    statutoryDeductionsRows: salaryDetails?.statDeductionsEmployeeLineEntries
      ? getDataArray(
          salaryDetails?.statDeductionsEmployeeLineEntries,
          'statutoryDeductions',
          currencySymbol,
          filterFunctions.benefitsRows,
        )
      : [],
    employerRetiralRows: salaryDetails?.statContributionsEmployerLineEntries
      ? getDataArray(
          salaryDetails?.statContributionsEmployerLineEntries,
          'employerRetiral',
          currencySymbol,
          filterFunctions.benefitsRows,
        )
      : [],
    fixedComponentsRows: [
      {
        fixedComponents: 'Gross Earnings',
        subLabel: '(Earnings + Benefits + Reimbursements)',
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.netPerPeriodEarnings) || '0'
        }`,
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.netAnnualEarnings) || '0'
        }`,
      },
      {
        fixedComponents: 'Gross Deductions',
        subLabel: '(Statutory Deductions from Employee) (exclusive of TDS/Income Tax **)',
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.netPerPeriodDeductions) || '0'
        }`,
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.netAnnualDeductions) || '0'
        }`,
      },
    ],
    netSalaryRows: [
      {
        netSalary: 'Net Salary',
        subLabel: '(Gross Earnings - Gross Deductions) (exclusive of TDS/Income Tax **)',
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.netPerPeriodSalary) || '0'
        }`,
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.netAnnualSalary) || '0'
        }`,
      },
    ],
    netVariablePayRows: [
      {
        netVariablePay: 'Net Variable Pay',
        subLabel: '(includes all witholdings **)',
        perPeriodAmount: `${currencySymbol} ${validateNumberElseHyphen(
          salaryDetails?.witholdingLineEntries?.reduce((sum, obj) => sum + obj.finalPerPeriodValue, 0) || '₹ 0',
        )}`,
        annualAmount: `${currencySymbol} ${validateNumberElseHyphen(
          salaryDetails?.witholdingLineEntries?.reduce((sum, obj) => sum + obj.finalAnnualValue, 0) || '₹ 0',
        )}`,
      },
    ],
    retiralRows: [
      {
        retiral: 'Employer Retiral and Admin Contributions',
        subLabel: '(Gratuity + PF + ESI + Admin charges) (** where applicable)',
        perPeriodAmount: salaryDetails?.statContributionsEmployerLineEntries
          ? `${currencySymbol} ${validateNumberElseHyphen(retiralFinalPerPeriodValue)}`
          : '₹0',
        annualAmount: salaryDetails?.statContributionsEmployerLineEntries
          ? `${currencySymbol} ${validateNumberElseHyphen(retiralFinalAnnualValue)}`
          : '₹0',
      },
    ],
    totalRows: [
      {
        total: 'Cost To Company ',
        subLabel:
          '(Gross Earnings + Variable Pay + Retiral & Admin' +
          'Contributions + Employee LWF Contributions ** (in case configured ' +
          'not to be deducted from employee)) (** where applicable)',
        perPeriodAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.costToCompanyPerPeriod) || ''
        }`,
        annualAmount: `${currencySymbol} ${
          validateNumberElseHyphen(salaryDetails?.salaryStructureInstance?.costToCompanyAnnual) || ''
        }  `,
      },
    ],
    isOverTimeApplicable: salaryDetails?.salaryStructureInstance?.isOTApplicable ? 'YES' : 'NO',
  };
  return salaryDetailsProps;
};

export { getConfiguredSalaryDetails };
