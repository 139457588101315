import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider, useTheme, Avatar } from '@mui/material';
import TitleWithText from 'components/Atoms/TitleWithText';
import Typographys from 'components/Atoms/Typography';
import { tdsStyle } from 'components/Organisms/MyPay/TDS/View/style';
import Checkbox from 'components/Atoms/Checkbox';
import { validateDateElseHyphen } from 'utils/commonUtils';
import OtherIncomeForm from './Form';
import { validateNumberElseHyphen } from '../../../../../../../utils/commonUtils';

const OtherIncome = ({ otherIncomeDetails, propertyList, allowEdit, formik, propertyTypeOption }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typographys variant="subtitle1" color="neutral.800">
        NOTE: Please follow the instructions below while filling in various sections:
      </Typographys>

      <Box mt={2} mb={4}>
        <Typographys variant="body1" color="neutral.800">
          1. Please list all the properties that you own (whether you live in one or have let out the same).
        </Typographys>
        <Typographys variant="body1" color="neutral.800">
          2. For each Letout Property, please fill in all the relevant details required (which includes the municipal
          taxes, interest on housing loan, date the loan was taken, loan amount, Pre-EMIs if any for the last two
          financial years etc).
        </Typographys>
        <Typographys variant="body1" color="neutral.800">
          3. For Self-Occupied property, it is sufficient to specify the interest paid on your housing loan.
        </Typographys>
        <Typographys variant="body1" color="neutral.800">
          4. For each property, in case you have co-borrowers for the loan, please specify details of your co-borrowes
          (including their names, PAN and Aadhaar).
        </Typographys>
        <Typographys variant="body1" color="neutral.800">
          5. In case of your self occupied property, if you have co-borrowers on your home loan and if you do intend to
          claim 100% of benefits for Tax exemptions under section 24(b), please do provide a declaration for the same by
          selecting the checkbox under declarations.
        </Typographys>
      </Box>
      {allowEdit ? (
        <OtherIncomeForm formik={formik} propertyTypeOption={propertyTypeOption} />
      ) : (
        <Box>
          <Typographys variant="subtitle1" color="neutral.800">
            Income from other sources: {otherIncomeDetails?.otherSources?.toFixed(1)}
          </Typographys>
          {!!propertyList?.length &&
            propertyList.map((property) => (
              <Box sx={{ borderTop: '1px solid', paddingBottom: '48px' }}>
                <Box mt={4.5} mb={2}>
                  <Typographys variant="subtitle2" color="neutral.800">
                    House Property Details
                  </Typographys>
                </Box>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText title="Property Type *" text={property.propertyType} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText title="Property Name" text={property.propertyName} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText title="Address" text={property.address} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText
                      title="Construction Completed on"
                      text={validateDateElseHyphen(property.constructionCompletedOn, 'DD MMMM YYYY')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText title="Loan Amount*" text={property.loanAmount !== '' ? property.loanAmount : '0'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText
                      title="Loan Borrowed On"
                      text={validateDateElseHyphen(property.loanBorrowedOn, 'DD MMMM YYYY')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText title="Lender Name" text={property.lenderName} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TitleWithText title="Lender PAN" text={property.lenderPAN} />
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1, borderColor: theme.palette.other[200] }} />
                <TitleWithText
                  title="Is Jointly Owned Property?"
                  text={property.isJointlyOwnedProperty === 'on' ? 'True' : 'False'}
                />
                {property.isJointlyOwnedProperty === 'on' && (
                  <>
                    <Divider sx={{ my: 1, borderColor: theme.palette.other[200] }} />
                    <Grid sx={tdsStyle.coBorrowerWrapper} container rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 1 Name" text={property.coBorrower1Name} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 1 PAN" text={property.coBorrower1Pan} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 1 Relationship" text={property.coBorrower1Relationship} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 2 Name" text={property.coBorrower2Name} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 2 PAN" text={property.coBorrower2Pan} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 2 Relationship" text={property.coBorrower2Relationship} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 3 Name" text={property.coBorrower3Name} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 3 PAN" text={property.coBorrower3Pan} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TitleWithText title="Co Borrower 3 Relationship" text={property.coBorrower3Relationship} />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Divider sx={{ mt: 1.5, mb: 2, borderColor: theme.palette.other[200] }} />
                <TitleWithText
                  title="Pre-EMI Interest - Year 1 (Exemption limited to 20% only)"
                  text={property.emiInterest1Year !== '' ? property.emiInterest1Year : '0'}
                />
                <TitleWithText
                  title="Pre-EMI Interest - Year 2 (Exemption limited to 20% only)"
                  text={property.emiInterest2Year !== '' ? property.emiInterest2Year : '0'}
                />
                {property.propertyType === 'Self-Occupied Property' && (
                  <>
                    <TitleWithText
                      title="Percentage of Interest on Loan Repaid by Self ((For Self-Occupied Property Only))"
                      text={property.percentageOfInterestRepaid}
                    />
                    <TitleWithText
                      title="Interest (On self occupied property only, per section 24)"
                      text={property.interest}
                    />
                  </>
                )}
                {property.propertyType !== 'Self-Occupied Property' && (
                  <>
                    <Box sx={tdsStyle.listIcon}>
                      <Avatar>A</Avatar>
                      <TitleWithText
                        title="Lettable Value *"
                        text={property.lettableValue !== '' ? property.lettableValue : '0'}
                      />
                    </Box>
                    <Box sx={tdsStyle.listIcon}>
                      <Avatar>B</Avatar>
                      <TitleWithText
                        title="Tax Paid To Local Authorities *"
                        text={property.taxPaidToLocalAuthorities !== '' ? property.taxPaidToLocalAuthorities : '0'}
                      />
                    </Box>
                    <Box sx={tdsStyle.listIcon}>
                      <Avatar>C</Avatar>
                      <TitleWithText
                        title="Standard Deduction On Lettable Value (30% of (A-B))"
                        text={
                          property.standardDeductionOnLettableValue !== ''
                            ? property.standardDeductionOnLettableValue
                            : '0'
                        }
                      />
                    </Box>
                    <Box sx={tdsStyle.listIcon}>
                      <Avatar>D</Avatar>
                      <TitleWithText
                        title="Interest Paid On Housing Loan (max 2,00,000 pa)*"
                        text={
                          property.interestPaidOnHousingLoan !== ''
                            ? validateNumberElseHyphen(property.interestPaidOnHousingLoan)
                            : '0'
                        }
                      />
                    </Box>
                    <Box sx={tdsStyle.listIcon}>
                      <Avatar>E</Avatar>
                      <TitleWithText
                        title="Net Income From House Property (A-B-C-D) (loss limited to 2,00,00 from FY 17-18)"
                        text={
                          property.netIncomeFromHouse !== ''
                            ? validateNumberElseHyphen(property.netIncomeFromHouse)
                            : '0'
                        }
                      />
                    </Box>
                  </>
                )}
                {property.propertyType === 'Self-Occupied Property' && (
                  <>
                    <Box mt={2}>
                      <Typographys variant="subtitle1" color="neutral.800">
                        Declaration (for claiming 100% interest on joint property)
                      </Typographys>
                    </Box>
                    <Box sx={tdsStyle.checkboxWithLabelView}>
                      <Checkbox checked={property?.isCompleteTaxExemAuthByAllBorrowers} color="secondary" disabled />
                      <Typographys variant="body2" color="neutral.800">
                        This is to inform the concerned authorities that the property mentioned is a joint property:
                        however the other owner(s) of the property is(are) not enjoying any tax benefits on the same and
                        has granted me the permission to avail tax benefits on the same.
                      </Typographys>
                    </Box>
                  </>
                )}
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

OtherIncome.defaultProps = {
  otherIncomeDetails: {},
  allowEdit: false,
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    values: {
      property: [],
      houseRent: [],
      investmentDec: [],
    },
    error: {},
  },
  propertyTypeOption: [],
  propertyList: [],
};

OtherIncome.propTypes = {
  otherIncomeDetails: PropTypes.shape({
    otherSources: PropTypes.number,
  }),
  propertyList: PropTypes.arrayOf(
    PropTypes.shape({
      propertyType: PropTypes.string,
      propertyName: PropTypes.string,
      address: PropTypes.string,
      constructionCompletedOn: PropTypes.string,
      loanAmount: PropTypes.number,
      loanBorrowedOn: PropTypes.string,
      lenderName: PropTypes.string,
      lenderPAN: PropTypes.string,
      isJointlyOwnedProperty: PropTypes.string,
      emiInterest1Year: PropTypes.number,
      emiInterest2Year: PropTypes.number,
      lettableValue: PropTypes.number,
      taxPaidToLocalAuthorities: PropTypes.number,
      standardDeductionOnLettableValue: PropTypes.number,
      interestPaidOnHousingLoan: PropTypes.number,
      netIncomeFromHouse: PropTypes.number,
    }),
  ),
  allowEdit: PropTypes.bool,
  formik: PropTypes.shape({
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }),
  propertyTypeOption: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
};

export default OtherIncome;
