import * as React from 'react';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typographys from 'components/Atoms/Typography';
import { Box } from '@mui/material';
import { TDSWorkingStyle } from 'components/Organisms/MyPay/TDSWorking/style';

const PastEmployerTaxesTable = ({ pastEmployerTaxDetails, currencySymbol }) => {
  return (
    <Paper sx={TDSWorkingStyle.hraTableWrapper}>
      <Box sx={{ display: 'flex', p: '12px 8px', backgroundColor: 'actions.100' }}>
        <Box sx={{ flexBasis: '15%' }}>
          <Typographys variant="body2">Employer Name</Typographys>
        </Box>
        <Box sx={{ flexBasis: '15%' }}>
          <Typographys variant="body2">Income (Salaries)</Typographys>
        </Box>
        <Box sx={{ flexBasis: '15%' }}>
          <Typographys variant="body2">Deductions (VIA)</Typographys>
        </Box>
        <Box sx={{ flexBasis: '15%' }}>
          <Typographys variant="body2">Net Tax Payable</Typographys>
        </Box>
        <Box sx={{ flexBasis: '12%' }}>
          <Typographys variant="body2">Tax Deducted</Typographys>
        </Box>
        <Box sx={{ flexBasis: '12%' }}>
          <Typographys variant="body2">PF Contributed</Typographys>
        </Box>
        <Box sx={{ flexBasis: '15%' }}>
          <Typographys variant="body2">Tax Refundable</Typographys>
        </Box>
      </Box>

      {pastEmployerTaxDetails?.map((detail, index) => (
        <Box key={detail?.employerName} sx={TDSWorkingStyle.hraTableRow}>
          <Box sx={{ flexBasis: '15%' }}>
            <Typographys variant="body2" color="neutral.600">
              {detail?.employerName || 'N/A'}
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '15%' }}>
            <Typographys variant="body2" color="neutral.600">
              {`${detail?.incomeChargeableUnderHeadSalaries?.toLocaleString('en-IN') || 0}`}
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '15%' }}>
            <Typographys variant="body2" color="neutral.600">
              {`${detail?.deductionUnderChapterVIA?.toLocaleString('en-IN') || 0}`}
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '15%' }}>
            <Typographys variant="body2" color="neutral.600">
              {`${detail.netTaxPayable?.toLocaleString('en-IN') || 0}`}
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '12%' }}>
            <Typographys variant="body2" color="neutral.600">
              {`${detail?.netTaxDeducted?.toLocaleString('en-IN') || 0}`}
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '12%' }}>
            <Typographys variant="body2" color="neutral.600">
              {`${detail?.netPFContributed?.toLocaleString('en-IN') || 0}`}
            </Typographys>
          </Box>
          <Box sx={{ flexBasis: '10%' }}>
            <Typographys variant="body2" color="neutral.600">
              {`${detail?.taxPayableRefundable?.toLocaleString('en-IN') || 0}`}
            </Typographys>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

PastEmployerTaxesTable.defaultProps = {
  pastEmployerTaxDetails: [],
  currencySymbol: '₹',
};

PastEmployerTaxesTable.propTypes = {
  pastEmployerTaxDetails: PropTypes.arrayOf(
    PropTypes.shape({
      employerName: PropTypes.string,
      incomeChargeableUnderHeadSalaries: PropTypes.number,
      deductionUnderChapterVIA: PropTypes.number,
      netTaxPayable: PropTypes.number,
      netTaxDeducted: PropTypes.number,
      netProfessionalTaxPaid: PropTypes.number,
      netPFContributed: PropTypes.number,
      taxPayableRefundable: PropTypes.number,
    }),
  ),
  currencySymbol: PropTypes.string,
};

export default PastEmployerTaxesTable;
