import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid } from '@mui/material';
import Typographys from 'components/Atoms/Typography';
import { isEmpty } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { useTheme } from '@mui/material/styles';
import TitleWithText from 'components/Atoms/TitleWithText';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from 'components/Atoms/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ToolTip from 'components/Atoms/ToolTip';
import { Formik, Form } from 'formik';
import { NEW_TAX_REGIME } from 'utils/SystemConfigConstants';
import ChooseFile from 'components/Atoms/ChooseFile';
import InputField from 'components/Atoms/InputField';
import { myPayStyle } from './style';
import { validateNumberElseHyphen } from '../../../../utils/commonUtils';

const UploadInvestmentProofs = ({
  formData,
  employeeDetails,
  handleCancel,
  handleUpload,
  handleDeleteFile,
  handleDownloadFile,
  handleSubmitInvestmentProof,
  userHasProofSubmitPermission,
  userhasProofUploadPermission,
}) => {
  const theme = useTheme();
  const [files, setFiles] = useState({});
  const [rowIndex, setRowIndex] = useState({});
  const [submitButton, setSubmitButton] = useState('');

  return (
    <Formik
      initialValues={{ proofSubmittedAmount: [] }}
      onSubmit={(values) => {
        if (submitButton === 'Submit Investment Proof') {
          handleUpload(values, files, rowIndex);
          setTimeout(async () => {
            await handleSubmitInvestmentProof(isEmpty(files));
          }, 3000);
        }
        if (submitButton === 'Upload Proofs') {
          handleUpload(values, files, rowIndex, 'upload proofs');
        }
      }}
    >
      {(formik) => {
        const handleChange = (event, row, rowInd) => {
          const file1 = event.target.files[0];
          setFiles({ ...files, [row?.deductionHeadID]: file1 });
          setRowIndex({ ...rowIndex, [rowInd]: row?.deductionHeadID });
          formik.setFieldValue(`proofSubmittedAmount[${rowInd}]`, row?.actualDeclairedAmount);
        };

        return (
          <Form component="form" onSubmit={formik.handleSubmit}>
            <Box>
              <PerfectScrollbar>
                <Box sx={{ maxHeight: 'calc(100vh - 33.3rem)' }}>
                  <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid item xs={12} sm={4} lg={3}>
                      <TitleWithText title="Employee*" text={employeeDetails?.name} />
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3}>
                      <TitleWithText title="Employee ID*" text={employeeDetails?.eid} />
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3}>
                      <TitleWithText title="Financial Year*" text={employeeDetails?.financialYear} />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                      <TitleWithText title="Assesment Year*" text={employeeDetails?.assesmentYear} />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                      <TitleWithText title="Investment Proof Status*" text={employeeDetails?.proofSubmissionStatus} />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                      <TitleWithText title="Form 12BB Status*" text={employeeDetails?.form12BBStatus || 'Draft'} />
                    </Grid>
                  </Grid>

                  <Box mt={2} sx={myPayStyle.tableWrapper}>
                    <Table className={myPayStyle.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Investment/Deduction Head</TableCell>
                          <TableCell>Actual Declared Amount</TableCell>
                          <TableCell>Upload Investment Proofs</TableCell>
                          <TableCell>Proof Submitted Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData?.map((row, index) => {
                          const proofAmountCalc = formik?.values?.proofSubmittedAmount[index] || 0;
                          return (
                            <TableRow>
                              <TableCell className={myPayStyle.tableCell}>
                                <Typographys variant="body2">{index + 1}</Typographys>
                              </TableCell>
                              <TableCell>
                                <Typographys variant="body2">
                                  {row.investmentHead}
                                  <ToolTip
                                    tooltipTitle={row.dataContent}
                                    title={row.dataOriginalTitle}
                                    placement="top"
                                  />
                                </Typographys>
                              </TableCell>
                              <TableCell>
                                <Typographys variant="body2">
                                  {validateNumberElseHyphen(row?.actualDeclairedAmount)}
                                </Typographys>
                              </TableCell>
                              <TableCell variant="body2">
                                {!row?.file && (
                                  <ChooseFile
                                    handleChange={(e) => {
                                      handleChange(e, row, index);
                                    }}
                                    value={files?.[row?.deductionHeadID]}
                                    showNoFileTitle
                                  />
                                )}

                                {!!row?.file && (
                                  <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Button
                                      variant="text"
                                      color="secondary"
                                      onClick={() => {
                                        handleDownloadFile(row?.file);
                                      }}
                                    >
                                      {row?.file.name}
                                    </Button>

                                    <DeleteIcon onClick={handleDeleteFile} color="action" />
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell>
                                <InputField
                                  onChange={formik.handleChange}
                                  value={proofAmountCalc}
                                  name={`proofSubmittedAmount[${index}]`}
                                  id={`proofSubmittedAmount[${index}]`}
                                  sx={{ width: '15rem', margin: 0 }}
                                  type="number"
                                  disabled={!formik?.values?.proofSubmittedAmount[index]}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </PerfectScrollbar>

              <Box sx={myPayStyle.btnContainer}>
                <Divider sx={{ my: 2, backgroundColor: theme.palette.neutral[200] }} />

                {employeeDetails?.taxRegime !== NEW_TAX_REGIME && (
                  <Box sx={myPayStyle.submitWrapper}>
                    <Button
                      size="large"
                      color="info"
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        handleCancel();
                      }}
                    >
                      Cancel
                    </Button>

                    {!!userHasProofSubmitPermission && (
                      <Button
                        size="large"
                        variant="outlined"
                        type="submit"
                        name="Submit Investment Proof"
                        onClick={() => setSubmitButton('Submit Investment Proof')}
                      >
                        Submit Investment Proofs
                      </Button>
                    )}
                    {!!userhasProofUploadPermission && (
                      <Button
                        type="submit"
                        size="large"
                        name="Upload Proofs"
                        onClick={() => setSubmitButton('Upload Proofs')}
                        disable={false}
                      >
                        Upload Proofs
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
UploadInvestmentProofs.defaultProps = {
  formData: [],
  employeeDetails: {},
  handleCancel: () => {},
  handleUpload: () => {},
  handleDeleteFile: () => {},
  handleDownloadFile: () => {},
  handleSubmitInvestmentProof: () => {},
};

UploadInvestmentProofs.propTypes = {
  formData: PropTypes.arrayOf(),
  employeeDetails: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    financialYear: PropTypes.string,
    assesmentYear: PropTypes.string,
    proofSubmissionStatus: PropTypes.string,
    form12BBStatus: PropTypes.string,
    taxRegime: PropTypes.string,
    eid: PropTypes.string,
  }),
  handleCancel: PropTypes.func,
  handleUpload: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  handleDownloadFile: PropTypes.func,
  handleSubmitInvestmentProof: PropTypes.func,
};
export default UploadInvestmentProofs;
