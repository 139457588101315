import dayjs from 'dayjs';

const getColumns = (sortedPayPeriods) => {
  const columns = [];
  columns.push({
    fieldName: 'category',
    label: 'Category',
    formatValue: ({ row, column }) => row[column.fieldName],
  });
  sortedPayPeriods?.forEach((payPeriod) => {
    columns.push({
      fieldName: dayjs(payPeriod?.payPeriodStartDate).format('MMMM').toLowerCase(),
      label: dayjs(payPeriod?.payPeriodStartDate).format('MMMM YYYY'),
      formatValue: ({ row, column }) => row[column.fieldName],
    });
  });
  columns.push({
    fieldName: 'subTotal',
    label: 'Sub Total',
    formatValue: ({ row, column }) => row[column.fieldName],
  });
  return [columns];
};

export default getColumns;
